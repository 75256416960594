import React from 'react';
import { Text, FormLabel, Paragraph, Button, InputText, Toast } from 'prixa-design-kit/dist';
import { postAPI } from '../../api/api-method';
import { DIAGNOSTIC_API } from '../../api/api-url';
import SurveySideSheet from './SurveySideSheet';
import { Icon } from 'prixa-design-kit/dist';

const imgSendEmail = `${process.env.REACT_APP_ASSET_URL}/illustration/sent-to-mail.png`;

const pages = {
  SEND_EMAIL_PAGE: 'Send Email Page',
  SEND_EMAIL_SUCCESS_PAGE: 'Send Email Success Page',
  SURVEY_PAGE: 'Survey Page',
};

const EmailSideSheet = (props: any) => {
  const [emailPage, setEmailPage] = React.useState(pages.SEND_EMAIL_PAGE);

  if (emailPage === pages.SEND_EMAIL_PAGE) {
    return <SendEmailPage sessionId={props.sessionId} setPage={setEmailPage}></SendEmailPage>;
  } else if (emailPage === pages.SEND_EMAIL_SUCCESS_PAGE) {
    return <SendEmailSuccessPage setPage={setEmailPage}></SendEmailSuccessPage>;
  } else if (emailPage === pages.SURVEY_PAGE) {
    return <SurveySideSheet sessionId={props.sessionId}></SurveySideSheet>;
  }

  return <div></div>;
};

const useInput = ({ type = 'text', err, placeholder }: any) => {
  const [value, setValue] = React.useState(null);
  const input = <InputText errors={err} placeholder={placeholder} setData={setValue} small={false} type={type} />;
  return [value, setValue, input];
};

const SendEmailPage = (props: any) => {
  const [sent, setSent] = React.useState(true);
  const [errUserName, setErrUserName] = React.useState(false);
  const [errEmail, setErrEmail] = React.useState(false);
  const [isErrorMsg, setError] = React.useState('');

  /*eslint-disable */
  const [userName, setUserName, userNameInput] = useInput({
    type: 'text',
    err: errUserName,
    placeholder: 'Nama panggilan Anda',
  });

  const [email, setEmail, emailInput] = useInput({
    type: 'email',
    err: errEmail,
    placeholder: 'nama@email.com',
  });
  /*eslint-enable */

  React.useEffect(() => {
    userName ? setErrUserName(false) : setErrUserName(true);
  }, [userName]);

  React.useEffect(() => {
    email ? setErrEmail(false) : setErrEmail(true);
  }, [email]);

  const sendEmail = (event: any) => {
    setSent(false);
    event.preventDefault();
    postAPI(DIAGNOSTIC_API.SEND_EMAIL, {
      email: email,
      sessionId: props.sessionId,
      username: userName,
    })
      .then(async (res: any) => {
        setSent(true);
        props.setPage(pages.SEND_EMAIL_SUCCESS_PAGE);
      })
      .catch(err => {
        setError(err.response.data.error || 'Terjadi kesalahan silahkan coba lagi.');
        setTimeout(() => {
          setError('');
        }, 3000);
        setSent(true);
      });
  };

  return (
    <React.Fragment>
      <form
        className="prixa-container is-top is-full-height"
        onSubmit={event => {
          sendEmail(event);
        }}
      >
        <div className="prixa-title">
          <Paragraph scale="heroTitle">Ke mana hasil pemeriksaan ini perlu dikirim?</Paragraph>
          <Paragraph scale="caption" className="prixa-title-sub">
            Data Anda tidak akan digunakan untuk keperluan lain
          </Paragraph>
        </div>
        <div className="margin-baseB">
          <FormLabel errors={false}>Nama</FormLabel>
          {userNameInput}
        </div>
        <div className="margin-largeB">
          <FormLabel errors={false}>Email</FormLabel>
          {emailInput}
        </div>
        {/* <div>
          <CheckBox label="Beri tahu saya informasi terbaru tentang Prixa"></CheckBox>
        </div> */}
        <div className="prixa-right-button">
          <Button type="submit" size="option" variant="primary" disabled={errUserName || errEmail || !sent}>
            {sent ? (
              'Kirim'
            ) : (
              <div style={{ margin: 'auto' }}>
                <Icon color="light" type="faCircleNotch" spin />
              </div>
            )}
          </Button>
        </div>
      </form>
      <Toast timeout={3000} message={isErrorMsg} variant="danger" show={isErrorMsg !== ''}></Toast>
    </React.Fragment>
  );
};

const SendEmailSuccessPage = (props: any) => {
  return (
    <div className="prixa-container">
      <div className="prixa-body">
        <div className="prixa-question-image">
          <img loading="lazy" width="240px" alt="Prixa Feedback" src={imgSendEmail} />
        </div>
        <div className="prixa-title">
          <Paragraph scale="question">Hasil pemeriksaan telah dikirim ke email Anda.</Paragraph>
          <Text scale="content" className="prixa-title-sub">
            Terima kasih sudah menggunakan Prixa, semoga Anda sehat selalu!
          </Text>
        </div>
      </div>
      <div className="prixa-right-button">
        <span onClick={() => props.setPage(pages.SURVEY_PAGE)}>
          <Button size="option" variant="primary">
            Selesai
          </Button>
        </span>
      </div>
    </div>
  );
};

export default EmailSideSheet;
