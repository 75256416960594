import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Toast, Text, Button } from 'prixa-design-kit/dist';
import { postAPI } from '../../../api/api-method';
import { USER_API } from '../../../api/api-url';
import { LoadPage } from '../../diagnostic/LoadPage';
import { ResetPasswordForm } from './ResetPasswordForm';
import { ResetPasswordContext } from './ResertPasswordContext';

const imgResetPasswordSuccess = `${process.env.REACT_APP_ASSET_URL}/illustration/Email-3.png`;

const ResetPasswordPage = () => {
  const [isErrorMsg, setError] = React.useState('');
  const [isLoad, setLoader] = React.useState(false);

  const history = useHistory();
  /* eslint-disable */
  function checkPasswordToken() {
    setLoader(true);
    postAPI(USER_API.FORGET_VERIFY(String(window.location.pathname).split('password/')[1]), {
      forgetPwdToken: String(window.location.pathname).split('password/')[1],
    })
      .then(() => {
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        history.push('/user');
      });
  }

  /* eslint-disable */
  useEffect(() => {
    checkPasswordToken();
  }, []);
  /* eslint-enable */

  return (
    <React.Fragment>
      {isLoad ? (
        <LoadPage />
      ) : (
        <div className="prixa-container">
          <ResetPasswordContext.Provider value={{ setError, isLoad, setLoader }}>
            <ResetPasswordForm />
          </ResetPasswordContext.Provider>
          <Toast timeout={3000} message={isErrorMsg} variant="danger" show={isErrorMsg !== ''}></Toast>
        </div>
      )}
    </React.Fragment>
  );
};

const ResetPasswordSuccessPage = () => {
  return (
    <div className="prixa-container">
      <div className="prixa-question-image">
        <img loading="lazy" width="240px" alt="Prixa Reset Password Success" src={imgResetPasswordSuccess} />
      </div>
      <div className="margin-baseB">
        <Text scale="heroTitle">Password akun Anda telah berhasil diganti.</Text>
      </div>
      <div>
        <Text scale="content" style={{ fontSize: '16px' }}>
          Silakan masuk ke akun Anda dengan email dan password baru.
        </Text>
      </div>
      <div className="text-center margin-largeT">
        <Button size="xLarge" variant="primary" href="/continue">
          Masuk
        </Button>
      </div>
    </div>
  );
};

export { ResetPasswordPage, ResetPasswordSuccessPage };
