import React from 'react';
import '../checkbox/checkbox.scss';

interface CheckBoxProps {
  label?: any;
  checked?: boolean;
  value: any;
  onChange?: any;
}

const CheckBox = (props: CheckBoxProps) => {
  return (
    <label className="prixa-checkbox">
      <input
        type="checkbox"
        className="prixa-checkbox-button"
        name="checkbox"
        onChange={() => props.onChange()}
        value={props.value}
      />
      <span className="prixa-checkbox-label">{props.label}</span>
    </label>
  );
};

export default CheckBox;
