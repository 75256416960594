import React from 'react';
import '../modal/modal.scss';
import { CSSTransition } from 'react-transition-group';

interface ModalProps {
  show: boolean;
  content: any;
  buttons: any;
}

const Modal: any = (props: ModalProps) => {
  return (
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={100}
      classNames={{
        enter: 'animated',
        enterActive: 'fade-enter-active',
        exit: 'animated',
        exitActive: 'fade-exit-active',
      }}
    >
      <div className="prixa-modal">
        <div className="prixa-modal-content">
          <div className="content">{props.content}</div>
          <div className="prixa-modal-action-button">{props.buttons}</div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Modal;
