import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LoadPage } from '../wrappers/diagnostic/LoadPage';
import { getAPI, signal } from '../api/api-method';
import { USER_API } from '../api/api-url';

// AfterDiagnose Routes = Diagnosis ID is needed.
// Routes throw error page asking user to do diagnosis first (if NOT LOG IN)
// Routes redirect to home (if LOG IN)

export const AfterDiagnoseRoute = ({ component: Component, ...rest }: any) => {
  if (String(localStorage.getItem('loginToken')).length > 10) {
    return <Redirect to="/home" />;
  } else {
    return <Route {...rest} component={Component} />;
  }
};

// Logged Routes = User need to be logged to access pages
export const LoggedRoute = ({ component: Component, ...rest }: any) => {
  const [routeState, setRouteState] = React.useState('load');
  const time = 1;

  React.useEffect(() => {
    getAPI(USER_API.INFO, String(localStorage.getItem('loginToken') || ''))
      .then((res: any) => {
        localStorage.setItem('profileData', JSON.stringify(res.profileData));
        setRouteState('verified');
      })
      .catch(err => {
        setRouteState('');
        localStorage.removeItem('loginToken');
        localStorage.removeItem('profileData');
        localStorage.removeItem('personID');
        localStorage.removeItem('isVerified');
        localStorage.removeItem('profileData');
        window.location.href = '/user';
      });
    signal.cancel('');
  }, [time]);

  if (routeState === 'load') {
    return <Route path={rest.path} component={LoadPage} />;
  } else if (routeState === 'verified') {
    return <Route path={rest.path}>{rest.children}</Route>;
  } else {
    return <Redirect to="/user" />;
  }
  // return <React.Fragment>{selectedComponent}</React.Fragment>;
};
