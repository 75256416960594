import React from 'react';
import { Text } from 'prixa-design-kit/dist';
import '../statuslist/statuslist.scss';

const statusDarahTinggi = `${process.env.REACT_APP_ASSET_URL}/status/darahtinggi-line.png`;
const statusDiabetes = `${process.env.REACT_APP_ASSET_URL}/status/diabetes-line.png`;
const statusMerokok = `${process.env.REACT_APP_ASSET_URL}/status/merokok-line.png`;
const statusMinumAlkohol = `${process.env.REACT_APP_ASSET_URL}/status/alkohol-line.png`;
const statusHamil = `${process.env.REACT_APP_ASSET_URL}/status/hamil-line.png`;
const yesStatusDarahTinggi = `${process.env.REACT_APP_ASSET_URL}/status/darahtinggi-solid.png`;
const yesStatusDiabetes = `${process.env.REACT_APP_ASSET_URL}/status/diabetes-solid.png`;
const yesStatusMerokok = `${process.env.REACT_APP_ASSET_URL}/status/merokok-solid.png`;
const yesStatusMinumAlkohol = `${process.env.REACT_APP_ASSET_URL}/status/alkohol-solid.png`;
const yesStatusHamil = `${process.env.REACT_APP_ASSET_URL}/status/hamil-solid.png`;

const StatusList = (props: { statusListProps: any }) => {
  return (
    <div className="prixa-status">
      <div
        className="prixa-status-wrapper"
        onClick={() => {
          props.statusListProps.setDarahTinggi(!props.statusListProps.darahTinggi);
        }}
      >
        {props.statusListProps.darahTinggi ? (
          <img loading="lazy" width="60px" height="60px" alt="Status Darah Tinggi" src={yesStatusDarahTinggi} />
        ) : (
          <img loading="lazy" width="60px" height="60px" alt="Status Darah Tinggi" src={statusDarahTinggi} />
        )}
        <br />{' '}
        <Text style={{ fontSize: '12px', lineHeight: '16px' }} scale="content">
          <b>
            Darah
            <br />
            Tinggi
          </b>
        </Text>
      </div>
      <div
        className="prixa-status-wrapper"
        onClick={() => {
          props.statusListProps.setDiabetes(!props.statusListProps.diabetes);
        }}
      >
        {props.statusListProps.diabetes ? (
          <img loading="lazy" width="60px" height="60px" alt="Status Diabetes" src={yesStatusDiabetes} />
        ) : (
          <img loading="lazy" width="60px" height="60px" alt="Status Diabetes" src={statusDiabetes} />
        )}
        <br />{' '}
        <Text style={{ fontSize: '12px', lineHeight: '16px' }} scale="content">
          <b>Diabetes</b>
        </Text>
      </div>
      {!props.statusListProps.showDrinkAndSmoke || (
        <React.Fragment>
          <div
            className="prixa-status-wrapper"
            onClick={() => {
              props.statusListProps.setMerokok(!props.statusListProps.merokok);
            }}
          >
            {props.statusListProps.merokok ? (
              <img loading="lazy" width="60px" height="60px" alt="Status Merokok" src={yesStatusMerokok} />
            ) : (
              <img loading="lazy" width="60px" height="60px" alt="Status Merokok" src={statusMerokok} />
            )}
            <br />{' '}
            <Text style={{ fontSize: '12px', lineHeight: '16px' }} scale="content">
              <b>Merokok</b>
            </Text>
          </div>
          <div
            className="prixa-status-wrapper"
            onClick={() => {
              props.statusListProps.setMinumAlkohol(!props.statusListProps.minumAlkohol);
            }}
          >
            {props.statusListProps.minumAlkohol ? (
              <img loading="lazy" width="60px" height="60px" alt="Status Minum Alkohol" src={yesStatusMinumAlkohol} />
            ) : (
              <img loading="lazy" width="60px" height="60px" alt="Status Minum Alkohol" src={statusMinumAlkohol} />
            )}
            <br />{' '}
            <Text style={{ fontSize: '12px', lineHeight: '16px' }} scale="content">
              <b>
                Minum
                <br />
                Alkohol
              </b>
            </Text>
          </div>
        </React.Fragment>
      )}
      {!props.statusListProps.showPregnancy || (
        <div
          className="prixa-status-wrapper"
          onClick={() => {
            props.statusListProps.setHamil(!props.statusListProps.hamil);
          }}
        >
          {props.statusListProps.hamil ? (
            <img loading="lazy" width="60px" height="60px" alt="Status Hamil" src={yesStatusHamil} />
          ) : (
            <img loading="lazy" width="60px" height="60px" alt="Status Hamil" src={statusHamil} />
          )}
          <br />{' '}
          <Text style={{ fontSize: '12px', lineHeight: '16px' }} scale="content">
            <b>Hamil</b>
          </Text>
        </div>
      )}
    </div>
  );
};

export default StatusList;
