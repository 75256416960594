import React from 'react';
import { Paragraph } from 'prixa-design-kit/dist';

interface StatusSideSheetProps {
  isCanPregnant: boolean;
  isCanDrinkAndSmoke: boolean;
  isFemale: Record<string, any>;
  data: Array<Record<string, any>>;
}

interface StatusExplanationProps {
  preconditionsDescription: string;
  preconditionsDescriptionCopy: string;
}

const StatusSideSheet = ({ isFemale, isCanPregnant, isCanDrinkAndSmoke, data }: StatusSideSheetProps) => {
  return (
    <div className="prixa-container is-top">
      {data.map((item: any) => {
        const isPregnant = item.type === 'pregnancy';
        const isSmokerOrDrinker = item.name === 'Smoker' || item.name === 'Alcohol drinker';
        if (isPregnant) {
          if (isFemale && isCanPregnant) {
            return (
              <StatusExplanation
                key={item.id}
                preconditionsDescription={item.preconditionsDescription}
                preconditionsDescriptionCopy={item.preconditionsDescriptionCopy}
              />
            );
          } else return null;
        } else if (isSmokerOrDrinker) {
          if (isCanDrinkAndSmoke) {
            return (
              <StatusExplanation
                key={item.id}
                preconditionsDescription={item.preconditionsDescription}
                preconditionsDescriptionCopy={item.preconditionsDescriptionCopy}
              />
            );
          } else return null;
        } else {
          return (
            <StatusExplanation
              key={item.id}
              preconditionsDescription={item.preconditionsDescription}
              preconditionsDescriptionCopy={item.preconditionsDescriptionCopy}
            />
          );
        }
      })}
    </div>
  );
};

const StatusExplanation = ({ preconditionsDescription, preconditionsDescriptionCopy }: StatusExplanationProps) => {
  return (
    <div className="margin-baseB">
      <Paragraph scale="pageTitle">{preconditionsDescription}</Paragraph>
      <Paragraph scale="content">{preconditionsDescriptionCopy}</Paragraph>
    </div>
  );
};

export default StatusSideSheet;
