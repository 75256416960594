export const diseaseAccuracyMap = {
  high: 'tinggi',
  medium: 'sedang',
  low: 'rendah',
};

export const diseaseAccuracyDescMap = {
  [diseaseAccuracyMap.high]: 'Kemungkinan Tinggi',
  [diseaseAccuracyMap.medium]: 'Kemungkinan Sedang',
  [diseaseAccuracyMap.low]: 'Kemungkinan Rendah',
};

export const priority = {
  homecare: 1,
  opd: 2,
};

export const triageMessage = {
  homecare:
    'Pengguna dengan keluhan serupa pada umumnya tidak memerlukan penanganan medis darurat. Namun, segera konsultasi dengan dokter dalam 2-3 hari ke depan jika gejala Anda memburuk atau ada gejala lain.',
  opd:
    'Prixa merekomendasikan konsultasi atau kunjungan ke dokter langsung agar Anda mendapat saran dan perawatan yang tepat sasaran.',
};

export const triageType = {
  homecare: 'Homecare',
  opd: 'OPD',
  poly: 'Poli Klinik',
  ugd: 'UGD',
};

export const classifyIconColor = {
  [diseaseAccuracyMap.high]: 'success',
  [diseaseAccuracyMap.medium]: 'secondary',
};

export const classifyTextColor: any = {
  [diseaseAccuracyMap.high]: 'questionLink2',
  [diseaseAccuracyMap.medium]: 'feedbackLink2',
};
