import React from 'react';
import Modal from '../../../components/modal/Modal';
import { Paragraph, Button } from 'prixa-design-kit/dist';

interface CloseModalProps {
  closePopup: boolean;
  setPopup: any;
}

export const CloseModal: any = (props: CloseModalProps) => {
  return (
    <div>
      <Modal
        show={props.closePopup}
        content={
          <React.Fragment>
            <Paragraph scale="question" color="#fff" className="margin-baseB color-danger">
              Mengakhiri Percakapan
            </Paragraph>
            <Paragraph scale="content" style={{ fontSize: '16px' }}>
              Setelah mengakhiri percakapan, Anda tidak akan dapat melanjutkan percakapan ini. Akhiri percakapan?
            </Paragraph>
          </React.Fragment>
        }
        buttons={
          <React.Fragment>
            <Button
              onClick={() => {
                props.setPopup(false);
              }}
              size="full"
              variant="disabled"
              className="prixa-cancel-button"
            >
              Batal
            </Button>
            <Button
              onClick={() => {
                props.setPopup(false);
                window.$crisp.push(['do', 'chat:close']);
                window.$crisp.push(['do', 'session:reset', [false]]);
                window.location.href = '/home';
              }}
              style={{
                background: '#ff6e5d',
              }}
              size="full"
              variant="primary"
            >
              Akhiri
            </Button>
          </React.Fragment>
        }
      />
    </div>
  );
};
