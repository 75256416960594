import React from 'react';
import { Toast } from 'prixa-design-kit/dist';
import SideSheet from '../../../components/sidesheet/SideSheet';
import { ForgetPasswordSideSheet } from '../forget-password-sidesheet/ForgetPasswordSideSheet';
import { LoginContext } from './LoginContext';
import { LoginForm } from './LoginForm';
import { GoogleLogin } from './GoogleLogin';

const LoginTab = () => {
  const [isErrorMsg, setError] = React.useState('');
  const [modal, setModal] = React.useState(false);

  return (
    <React.Fragment>
      <LoginContext.Provider value={{ setError, setModal }}>
        <LoginForm />
      </LoginContext.Provider>
      <GoogleLogin />
      <Toast timeout={3000} message={isErrorMsg} variant="danger" show={isErrorMsg !== ''}></Toast>
      <SideSheet
        setModal={setModal}
        show={modal}
        title="Lupa Password"
        content={<ForgetPasswordSideSheet setModal={setModal} />}
      ></SideSheet>
    </React.Fragment>
  );
};

export default LoginTab;
