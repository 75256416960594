import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Text } from 'prixa-design-kit/dist';

const imgInitialPage = `${process.env.REACT_APP_ASSET_URL}/illustration/Sign-In.png`;

const InitialPage = (props: any) => {
  const history = useHistory();

  const login = () => {
    history.push({
      pathname: '/continue',
      state: { option: 'Masuk' },
    });
  };

  const signUp = () => {
    history.push({
      pathname: '/continue',
      state: { option: 'Daftar' },
    });
  };

  return (
    <div className="prixa-container">
      <div className="prixa-question-image">
        <img loading="lazy" width="240px" alt="Prixa Initial Page" src={imgInitialPage} />
      </div>
      <div className="text-center">
        <div className="margin-largeB">
          <Text style={{ fontSize: '16px' }} scale="content">
            Daftarkan atau masuk ke akun Prixa Anda untuk melanjutkan.
          </Text>
        </div>
        <div className="margin-smallB">
          <Button onClick={() => signUp()} size="xLarge" variant="outline">
            Daftar
          </Button>
        </div>
        <div>
          <Button onClick={() => login()} size="xLarge" variant="primary">
            Masuk
          </Button>
        </div>
      </div>
    </div>
  );
};

export { InitialPage };
