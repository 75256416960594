import React from 'react';
import { useLocation } from 'react-router-dom';
import { Text, Button, Toast } from 'prixa-design-kit/dist';
import { postAPI } from '../../../api/api-method';
import { USER_API } from '../../../api/api-url';
import { useInput } from '../../../utils/useInput';

const imgForgetPasswordSent = `${process.env.REACT_APP_ASSET_URL}/illustration/Email-2.png`;

interface ForgetPasswordProps {
  setModal: any;
}

const ForgetPasswordSideSheet = ({ setModal }: ForgetPasswordProps) => {
  const pages = {
    FORGET_PASSWORD: 'Forget Password',
    FORGET_PASSWORD_SENT: 'Forget Password Sent',
  };

  const [page, setPage] = React.useState(pages.FORGET_PASSWORD);

  if (page === pages.FORGET_PASSWORD) {
    return <ForgetPasswordPage setPage={setPage} />;
  } else {
    return <ForgetPasswordSentPage setModal={setModal} />;
  }
};

const ForgetPasswordPage = ({ setPage }: any) => {
  const [isValid, setIsValid] = React.useState(false);
  const [isErrorMsg, setError] = React.useState('');
  const [isLoad, setLoader] = React.useState(false);

  const email = useInput({
    type: 'email',
    placeholder: 'nama@email.com',
  });

  const pages = {
    FORGET_PASSWORD: 'Forget Password',
    FORGET_PASSWORD_SENT: 'Forget Password Sent',
  };

  const inputList = [email.input];

  React.useEffect(() => {
    const isEmailValid = email.value && !email.error;
    isEmailValid ? setIsValid(true) : setIsValid(false);
  }, [email]);

  const forgotPassword = (event: React.FormEvent<HTMLFormElement>): void => {
    setLoader(true);
    postAPI(USER_API.FORGET_PASSWORD, {
      email: email.value,
      diagnosticSessionID: localStorage.getItem('DiagnosisID'),
    })
      .then(() => {
        setLoader(false);
        setPage(pages.FORGET_PASSWORD_SENT);
      })
      .catch(err => {
        setError(err.response.data.error);
        setTimeout(() => {
          setError('');
        }, 3000);
        setLoader(false);
      });
  };

  return (
    <div className="prixa-container">
      <div className="prixa-title margin-baseB">
        <Text scale="question">Ke mana petunjuk pemulihan akun perlu dikirim?</Text>
      </div>
      <div className="margin-baseB">
        <Text scale="caption">Pastikan email yang Anda masukkan benar dan Anda dapat mengakses email tersebut.</Text>
      </div>
      <form onSubmit={(event: React.FormEvent<HTMLFormElement>): void => forgotPassword(event)}>
        {inputList.map((item, key: number) => {
          return (
            <div key={key} className="margin-baseB">
              {item}
            </div>
          );
        })}
        <div className="text-center margin-largeT">
          <Button
            onClick={(e: any) => forgotPassword(e)}
            size="xLarge"
            variant="primary"
            disabled={!isValid}
            loading={isLoad}
          >
            Kirim
          </Button>
        </div>
      </form>
      <Toast timeout={3000} message={isErrorMsg} variant="danger" show={isErrorMsg !== ''}></Toast>
    </div>
  );
};

const ForgetPasswordSentPage = ({ setModal }: ForgetPasswordProps) => {
  const location: any = useLocation();

  const closeForgetPasswordPage = () => {
    setModal(false);
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  };

  return (
    <div className="prixa-container">
      <div className="prixa-question-image">
        <img loading="lazy" width="240px" alt="Prixa Forget Password" src={imgForgetPasswordSent} />
      </div>
      <div className="margin-baseB">
        <Text scale="heroTitle">Petunjuk pemulihan akun telah dikirim.</Text>
      </div>
      <div>
        <Text scale="content" style={{ fontSize: '16px' }}>
          Silakan periksa email <b>{location.state ? location.state.email : 'Anda'}</b> dan ikuti petunjuk yang
          diberikan untuk memulihkan akun Anda.
        </Text>
      </div>
      <div className="text-center margin-largeT">
        <Button onClick={() => closeForgetPasswordPage()} size="xLarge" variant="primary">
          Tutup
        </Button>
      </div>
    </div>
  );
};

export { ForgetPasswordSideSheet };
