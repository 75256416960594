import React from 'react';

export const Chat = () => {
  /* eslint-disable */
  const checkChatCreated = () => {
    if (document.getElementsByClassName('crisp-client').length) {
      document.getElementsByClassName('crisp-kquevr')[0] && document.getElementsByClassName('crisp-kquevr')[0].remove();
      document.getElementsByClassName('crisp-zyxrmn')[0] && document
        .getElementsByClassName('crisp-zyxrmn')[0]
        .setAttribute('style', 'height: calc(100vh - 70px) !important;');
      clearInterval(intervalCheckCreated);
    }
  };

  const intervalCheckCreated = setInterval(checkChatCreated, 100);

  React.useEffect(() => {
    if (localStorage.getItem('loginToken')) {
      const s = document.createElement('script');
      s.src = 'https://client.crisp.chat/l.js';
      s.async = true;
      document.getElementsByTagName('head')[0].appendChild(s);
      checkChatCreated();
    }
  }, []);
  /* eslint-enable */

  return <div></div>;
};
