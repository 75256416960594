import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import './styles/base.scss';
import './styles/media.scss';
import { PrixaStyle } from 'prixa-design-kit/dist';
import { ErrorPage } from './wrappers/diagnostic/ErrorPage';
import { LoadPage } from './wrappers/diagnostic/LoadPage';
import App from './wrappers/App';
import * as serviceWorker from './serviceWorker';
import { getAPI } from './api/api-method';
import { PARTNER_APP_API } from './api/api-url';
import Container from './components/container/Container';
import { getPartnerID, getAppID } from './utils/constant';

declare global {
  interface Window {
    CRISP_WEBSITE_ID: string;
    $crisp: any;
  }
}

const CallAPI: React.FC = () => {
  const [load, setLoader] = useState(true);
  const [error, setError] = useState('');
  const [{ title, subtitle }, setHeader] = useState({ title: '', subtitle: '' });
  const [percentage, setPercentage] = useState(0);

  function doSetHeader(title?: string, subtitle?: string) {
    setHeader({ title: title || '', subtitle: subtitle || '' });
  }

  function doSetPercentage(percent?: number) {
    setPercentage(percent || 0);
  }

  function doSetError(error?: string) {
    setError(error || '');
  }

  useEffect(() => {
    getAPI(PARTNER_APP_API.PARTNER_APP_ID(getPartnerID, getAppID))
      .then(() => {
        localStorage.setItem('getPartnerID', getPartnerID);
        localStorage.setItem('getAppID', getAppID);
        setLoader(false);
      })
      .catch(err => {
        setError('Maaf, ada kesalahan dari kami');
        setLoader(false);
      });
  }, []);

  return (
    <Container
      title={title}
      subtitle={subtitle}
      percentage={percentage}
      content={
        <React.Fragment>
          {load ? (
            <LoadPage />
          ) : error === '' ? (
            <App setHeader={doSetHeader} setPercentage={doSetPercentage} setError={doSetError} />
          ) : (
            <ErrorPage text={error} goTo="https://prixa.ai" goToString="Ke halaman Prixa.ai" />
          )}
        </React.Fragment>
      }
    />
  );
};

ReactDOM.render(
  <PrixaStyle>
    <CallAPI />
  </PrixaStyle>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
