import React from 'react';
import '../radio/radio.scss';

const Radio = (props: { data: any; setData: any; selected: any }) => {
  return (
    <div className="flex-inline margin-smallT">
      {props &&
        props.data &&
        props.data.map((item: any) => {
          return (
            <label className="prixa-radio" key={item.id}>
              <input
                type="radio"
                className="prixa-radio-button"
                name="radio-btn"
                checked={props.selected === item}
                value={item.id}
                onChange={() => {
                  props.setData(item);
                }}
              />
              <span className="prixa-radio-label">{item.nameIndo || item.name}</span>
            </label>
          );
        })}
    </div>
  );
};

export default Radio;
