import React from 'react';
import { Route, BrowserRouter, Switch, MemoryRouter, Redirect } from 'react-router-dom';
import { SetContainerProps } from '../wrappers/App';
import { LoggedRoute, AfterDiagnoseRoute } from './routes-check';
import { loggedURLs, regisSign, diagnosisEngine } from './urls';

const Routes = (props: SetContainerProps) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <MemoryRouter>
            {diagnosisEngine(props).map((data, i) => {
              if (data.enable) {
                return (
                  <Route path={data.path} exact={data.exact} key={i}>
                    {data.component}
                  </Route>
                );
              } else return <Redirect key={i} to={`${process.env.REACT_APP_USER_ON === 'true' ? '/home' : '/'}`} />;
            })}
          </MemoryRouter>
        </Route>
        <Route path="/partner/:partnerId/app/:applicationId" exact>
          <MemoryRouter>
            {diagnosisEngine(props).map((data, i) => {
              if (data.enable) {
                return (
                  <Route path={data.path} exact={data.exact} key={i}>
                    {data.component}
                  </Route>
                );
              } else return <Redirect key={i} to={`${process.env.REACT_APP_USER_ON === 'true' ? '/home' : '/'}`} />;
            })}
          </MemoryRouter>
        </Route>
        {loggedURLs(props).map((data, i) => {
          if (data.enable) {
            return (
              <LoggedRoute path={data.path} exact={data.exact} key={i}>
                {data.component}
              </LoggedRoute>
            );
          } else return <Redirect key={i} to={`${process.env.REACT_APP_USER_ON === 'true' ? '/home' : '/'}`} />;
        })}
        {regisSign.map((data, i) => {
          if (data.enable) {
            return (
              <AfterDiagnoseRoute path={data.path} exact={data.exact} key={i}>
                {data.component}
              </AfterDiagnoseRoute>
            );
          } else return <Redirect key={i} to={`${process.env.REACT_APP_USER_ON === 'true' ? '/home' : '/'}`} />;
        })}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
