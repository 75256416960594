import React, { useEffect, useState } from 'react';
import { Button, Text, IconSolid, Toast, Icon } from 'prixa-design-kit/dist';
import { useHistory } from 'react-router-dom';
import { useInput } from '../../../utils/useInput';
import Modal from '../../../components/modal/Modal';
import { postAPI } from '../../../api/api-method';
// import { postAPI, putAPI } from '../../../api/api-method';
import { USER_API } from '../../../api/api-url';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar, Day, utils } from 'react-modern-calendar-datepicker';
import './personalInformation.scss';
// import { getAppID, getPartnerID } from '../../../utils/constant';

const PersonalInformationPage = () => {
  const [sent, setSent] = useState(true);
  const [isLoad, setLoad] = useState(false);
  const [isErrorMsg, setError] = useState('');
  const [email, setEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState();
  const [selectedDay, setSelectedDay] = useState<Day>();
  /* eslint-disable */
  const name = useInput({
    label: 'Nama',
    type: 'text',
    placeholder: 'Nama Lengkap',
  });

  const address = useInput({
    label: 'Alamat',
    type: 'textarea',
    rows: 3,
    placeholder: 'Alamat Lengkap',
    isValidate: false,
  });

  const phoneNumber = useInput({
    label: 'Nomor Telepon',
    type: 'text',
    placeholder: 'Nomor Telepon',
    isValidate: false,
  });

  const inputList = [name.input, address.input, phoneNumber.input];
  const history = useHistory();

  const [showModal, setModal] = React.useState(false);

  const dayOfBirth = ('0' + selectedDay?.day).slice(-2);
  const monthOfBirth = ('0' + selectedDay?.month).slice(-2);
  const yearOfBirth = selectedDay?.year;
  const DOB = selectedDay ? dayOfBirth + '/' + monthOfBirth + '/' + yearOfBirth : dateOfBirth ? dateOfBirth : null;

  const savePersonalInformation = () => {
    setLoad(true);
    postAPI(
      USER_API.UPDATE,
      {
        profileData: {
          birthdate: DOB,
          phone: phoneNumber.value,
          name: name.value,
          address: address.value,
        },
      },
      undefined,
      String(localStorage.getItem('loginToken')),
    )
      .then(async (res: any) => {
        setLoad(false);
        history.push('/profile');
      })
      .catch(err => {
        setError(err.response.data.error);
        setTimeout(() => {
          setError('');
        }, 3000);
        setLoad(false);
      });
  };

  const submitProfile = () => {
    const isFirstAddedDOB = !dateOfBirth && selectedDay;
    isFirstAddedDOB ? setModal(true) : savePersonalInformation();
  };

  useEffect(() => {
    const profileDataLocalStorage = localStorage.getItem('profileData');
    const profileData = JSON.parse(String(profileDataLocalStorage));
    name.setValue && name.setValue(profileData.name);
    setEmail(profileData.email);
    address.setValue && address.setValue(profileData.address);
    phoneNumber.setValue && phoneNumber.setValue(profileData.phone);
    setDateOfBirth(profileData.birthdate);
    setSent(true);
  }, []);
  /* eslint-enable */

  useEffect(() => {
    const isNameValid = name.value && !name.error;
    isNameValid ? setSent(false) : setSent(true);
  }, [name]);

  return (
    <React.Fragment>
      <div className="prixa-container is-top">
        <ProfilePictureSection />
        <EmailAndPasswordSection email={email} />
        <DateOfBirthSection dateOfBirth={dateOfBirth} selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
        {inputList.map((item, key: number) => {
          return (
            <div key={key} className="margin-baseB">
              {item}
            </div>
          );
        })}
      </div>
      <Toast timeout={3000} message={isErrorMsg} variant="danger" show={isErrorMsg !== ''}></Toast>
      <Modal
        show={showModal}
        content={
          <React.Fragment>
            <Text className="margin-baseB" scale="question">
              Konfirmasi Tanggal Lahir
            </Text>
            <Text scale="content" style={{ fontSize: '16px' }}>
              Tanggal lahir yang Anda tambahkan tidak dapat diganti setelah disimpan. Pastikan tanggal lahir Anda sudah
              sesuai.
            </Text>
          </React.Fragment>
        }
        buttons={
          <React.Fragment>
            <Button
              onClick={() => {
                setModal(false);
              }}
              size="full"
              variant="disabled"
              className="prixa-cancel-button"
            >
              Batal
            </Button>
            <Button onClick={() => savePersonalInformation()} size="full" variant="primary">
              Simpan
            </Button>
          </React.Fragment>
        }
      />
      <SaveButton sent={sent} onSave={submitProfile} isLoad={isLoad} />
    </React.Fragment>
  );
};

const ProfilePictureSection = () => {
  const [load, setLoad] = useState(false);
  // const [url, setURL] = useState('https://picsum.photos/200');
  // const [isErrorMsg, setError] = React.useState('');
  const isErrorMsg = '';
  const url = 'https://picsum.photos/200';

  // const uploadImage = async (e: any) => {
  //   try {
  //     setLoad(true);
  //     const file = e.target.files[0];
  //     const upload: any = await postAPI(
  //       USER_API.UPLOAD_URL,
  //       {},
  //       {
  //         Authorization: `Bearer ${String(localStorage.getItem('loginToken'))}`,
  //         'Content-Type': file.type,
  //         'X-Prixa-API-Key': `${process.env.REACT_APP_API_KEY}`,
  //         'X-Partner-Id': getPartnerID,
  //         'X-Partner-App-Id': getAppID,
  //       },
  //     );

  //     await putAPI(upload.url, file, {
  //       'Content-Type': file.type,
  //     });

  //     postAPI(
  //       USER_API.UPDATE,
  //       {
  //         profileData: {
  //           avatarURL: upload.name,
  //         },
  //       },
  //       undefined,
  //       String(localStorage.getItem('loginToken')),
  //     ).then(async (res: any) => {
  //       window.location.reload();
  //     });
  //   } catch (error) {
  //     setLoad(false);
  //     setError("Can't upload image");
  //     setTimeout(() => {
  //       setError('');
  //     }, 3000);
  //   }
  // };

  useEffect(() => {
    setLoad(true);
    // const profileDataLocalStorage = localStorage.getItem('profileData');
    // const profileData = JSON.parse(String(profileDataLocalStorage));
    // profileData && profileData.avatarURL && setURL(profileData.avatarURL || 'https://picsum.photos/200');
    setTimeout(() => {
      setLoad(false);
    }, 1000);
  }, []);

  return (
    <div className="text-center margin-baseB">
      {/* <Text scale="feedbackLink2">Ganti Foto</Text> */}
      {load ? (
        <div style={{ width: '20px', margin: 'auto' }}>
          <Icon color="primary" type="faCircleNotch" spin />
        </div>
      ) : (
        <img
          loading="lazy"
          height="64px"
          width="64px"
          alt="User Profile"
          src={url}
          style={{
            borderRadius: '100%',
            height: '64px',
            width: '64px',
            objectFit: 'cover',
            border: '4px solid #aaaaaa',
            marginBottom: '8px',
          }}
        />
      )}
      <br />
      {/* <input
        type="file"
        id="prixa-file-upload"
        onChange={e => {
          uploadImage(e);
        }}
      /> */}
      <Toast timeout={3000} message={isErrorMsg} variant="danger" show={isErrorMsg !== ''}></Toast>
    </div>
  );
};

const EmailAndPasswordSection = ({ email }: any) => {
  return (
    <div className="prixa-pop-container margin-baseB">
      <EmailSection email={email} />
      <PasswordSection />
    </div>
  );
};

const EmailSection = ({ email }: any) => {
  return (
    <div className="prixa-space-between padding-tinyB" style={{ borderBottom: '1px solid rgb(112,112,112, .25)' }}>
      <Text scale="content" style={{ fontWeight: 'bold' }}>
        Email
      </Text>
      <Text scale="content">{email}</Text>
    </div>
  );
};

const PasswordSection = () => {
  return (
    <a href="/change-password" className="prixa-space-between padding-tinyT">
      <Text scale="content" style={{ fontWeight: 'bold' }}>
        Ganti Password
      </Text>
      <Icon style={{ color: '#4C4F54' }} type="faChevronRight" />
    </a>
  );
};

const DateOfBirthSection = ({ dateOfBirth, selectedDay, setSelectedDay }: any) => {
  const [showModal, setModal] = React.useState(false);
  const [isDisabled, setDisabled] = useState(true);

  useEffect(() => {
    selectedDay ? setDisabled(false) : setDisabled(true);
  }, [selectedDay]);

  return (
    <React.Fragment>
      <div className="prixa-pop-container margin-largeB">
        <div className="prixa-space-between">
          <Text scale="content" style={{ fontWeight: 'bold' }}>
            Tanggal Lahir
          </Text>
          {dateOfBirth ? (
            <Text scale="content" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
              {dateOfBirth}
            </Text>
          ) : selectedDay ? (
            <Text scale="content" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
              {('0' + selectedDay.day).slice(-2)}/{('0' + selectedDay.month).slice(-2)}/{selectedDay.year}
              <span onClick={() => setSelectedDay(undefined)} style={{ marginLeft: '8px' }}>
                <IconSolid
                  backgroundColor="disabled"
                  backgroundSize="18px"
                  color="white"
                  margin="0px"
                  type="faTimes"
                  width="6px"
                ></IconSolid>
              </span>
            </Text>
          ) : (
            <div onClick={() => setModal(true)}>
              <Text scale="feedbackLink" style={{ cursor: 'pointer' }}>
                Tambah
              </Text>
            </div>
          )}
        </div>
      </div>
      <Modal
        show={showModal}
        content={
          <React.Fragment>
            <Text className="margin-baseB" scale="question">
              Pilih Tanggal Lahir Anda
            </Text>
            <Text className="margin-baseB" scale="caption">
              Tanggal lahir yang Anda tambahkan tidak dapat diganti setelah disimpan. Pastikan tanggal lahir Anda sudah
              sesuai.
            </Text>
            <div className="prixa-birtdate-calendar">
              <Calendar
                value={selectedDay}
                onChange={setSelectedDay}
                maximumDate={utils('en').getToday()}
                shouldHighlightWeekends
              />
            </div>
          </React.Fragment>
        }
        buttons={
          <React.Fragment>
            <Button
              onClick={() => {
                setSelectedDay(undefined);
                setModal(false);
              }}
              size="full"
              variant="disabled"
              className="prixa-cancel-button"
            >
              Batal
            </Button>
            <Button
              onClick={() => {
                setModal(false);
              }}
              size="full"
              variant="primary"
              disabled={isDisabled}
            >
              Pilih
            </Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

const SaveButton = ({ sent, onSave, isLoad }: any) => {
  return (
    <div className="prixa-footer-button">
      <Button onClick={() => onSave()} size="full" variant="primary" disabled={sent} loading={isLoad}>
        Simpan
      </Button>
    </div>
  );
};

export { PersonalInformationPage };
