import React, { useEffect, useState, createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import LoginTab from '../login-tab/LoginTab';
import SignUpTab from '../sign-up-tab/SignUpTab';
import * as Constants from './constant';

const ContinueContext = createContext({} as any);

const ContinuePage = () => {
  const [navigation, setNavigation] = useState(Constants.OPTION.login);
  const location: any = useLocation();

  useEffect(() => {
    const tab = location.state ? location.state.option : Constants.OPTION.login;
    tab === Constants.OPTION.login ? setNavigation(Constants.OPTION.login) : setNavigation(Constants.OPTION.signup);
  }, [location]);

  const isLoginTab = navigation === Constants.OPTION.login;
  return (
    <React.Fragment>
      <ContinueContext.Provider value={{ navigation, setNavigation }}>
        <ContinueTab />
      </ContinueContext.Provider>
      <div className="prixa-container">{isLoginTab ? <LoginTab /> : <SignUpTab />}</div>
    </React.Fragment>
  );
};

const ContinueTab = () => {
  const { navigation, setNavigation } = useContext(ContinueContext);

  return (
    <div className="prixa-continue-navigation">
      {Constants.TAB.map((item: Constants.TabProps, key: number) => {
        return (
          <div
            key={key}
            onClick={() => setNavigation(item.option)}
            className={navigation === item.option ? 'navigation active' : 'navigation'}
          >
            {item.name}
          </div>
        );
      })}
    </div>
  );
};

export { ContinuePage };
