import React from 'react';
import '../container/container.scss';
import Header from '../header/Header';
import { ProgressBar } from 'prixa-design-kit/dist';

interface ContainerProps {
  title?: string;
  subtitle?: string;
  percentage?: number;
  content: any;
}

const Container = (props: ContainerProps) => {
  const [percentage, setPercentage] = React.useState(0);
  const pageWithoutHeader = ['/initiate'];

  /* eslint-disable */
  React.useEffect(() => {
    setPercentage(props.percentage || 0);
  }, [props.percentage]);
  /* eslint-enable */

  return (
    <React.Fragment>
      {(() => {
        if (!pageWithoutHeader.includes(window.location.pathname)) {
          return <Header title={props.title} subtitle={props.subtitle}></Header>;
        }
      })()}
      {(() => {
        if (percentage) {
          return <ProgressBar className="display-flex" progressBarSize={percentage}></ProgressBar>;
        }
      })()}
      {(() => {
        if (!pageWithoutHeader.includes(window.location.pathname)) {
          return <div className="prixa">{props.content}</div>;
        } else {
          return <div className="prixa is-full-height">{props.content}</div>;
        }
      })()}
    </React.Fragment>
  );
};

export default Container;
