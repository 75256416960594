import React from 'react';
import { Icon, Text, Button } from 'prixa-design-kit/dist';

interface ErrorProps {
  text?: string;
  goTo?: string;
  goToString?: string;
}

export const ErrorPage = (props: ErrorProps) => {
  return (
    <div className="prixa-container is-middle is-full is-center">
      {!props.text ? (
        <Icon color="danger" type="faExclamation" style={{ fontSize: '36px', margin: '35vh auto' }} />
      ) : (
        <div style={{ width: '70%', textAlign: 'center', margin: '31vh auto' }}>
          <Icon color="danger" type="faExclamation" style={{ fontSize: '36px', margin: 'auto' }} />
          <br />
          <Text scale="errorMessage">{props.text}</Text>
          <br />
          {props.goTo ? (
            <Button href={props.goTo} size="large" variant="primary" className="margin-largeT">
              {props.goToString || 'Ke Halaman Utama'}
            </Button>
          ) : (
            <span />
          )}
        </div>
      )}
    </div>
  );
};
