import React, { useState, useContext, useEffect } from 'react';
import { Button } from 'prixa-design-kit/dist';
import { useInput } from '../../../utils/useInput';
import { useHistory } from 'react-router-dom';
import { postAPI } from '../../../api/api-method';
import { USER_API } from '../../../api/api-url';
import { SignUpContext } from './SignUpContext';

export const SignUpForm = () => {
  const { setError } = useContext(SignUpContext);
  const [isValid, setIsValid] = useState(false);
  const [isLoad, setLoader] = useState(false);

  const name = useInput({
    label: 'Nama',
    type: 'text',
    placeholder: 'Nama Lengkap',
  });

  const email = useInput({
    label: 'Email',
    type: 'email',
    placeholder: 'nama@email.com',
  });

  const password = useInput({
    label: 'Password',
    type: 'password',
    placeholder: 'Tulis Password',
    errMessage: 'Password harus mengandung huruf kapital, kecil, angka dan minimal 8 karakter',
  });

  const confirmPassword = useInput({
    label: 'Konfirmasi Password',
    type: 'password',
    placeholder: 'Tulis Password',
    confirmWith: password.value,
  });

  const inputList = [name.input, email.input, password.input, confirmPassword.input];

  const history = useHistory();

  useEffect(() => {
    const isNameValid = name.value && !name.error;
    const isEmailValid = email.value && !email.error;
    const isPasswordValid = password.value && !password.error;
    const isConfirmPasswordValid = confirmPassword.value && !confirmPassword.error;
    isNameValid && isEmailValid && isPasswordValid && isConfirmPasswordValid ? setIsValid(true) : setIsValid(false);
  }, [name, email, password, confirmPassword]);

  const registration = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setLoader(true);
    postAPI(USER_API.REGISTER, {
      authData: {
        email: email.value,
        password: password.value,
        passwordConfirmation: confirmPassword.value,
        diagnosticSessionID: localStorage.getItem('DiagnosisID'),
      },
      profileData: {
        email: email.value,
        phone: '',
        name: name.value,
        gender: 'string',
        password: password.value,
      },
    })
      .then(() => {
        setLoader(false);
        history.push('/verification-sent');
      })
      .catch(err => {
        setError(err.response.data.error);
        setTimeout(() => {
          setError('');
        }, 3000);
        setLoader(false);
      });
  };

  return (
    <form onSubmit={(event: React.FormEvent<HTMLFormElement>): void => registration(event)}>
      {inputList.map((item, key: number) => {
        return (
          <div key={key} className="margin-baseB">
            {item}
          </div>
        );
      })}
      <div className="text-center margin-largeT">
        <Button onClick={(e: any) => registration(e)} size="xLarge" disabled={!isValid} loading={isLoad}>
          Daftar
        </Button>
      </div>
    </form>
  );
};
