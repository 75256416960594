import React, { useEffect, useState } from 'react';
import { Text } from 'prixa-design-kit/dist';
import CtaFeature from '../../components/cta-feature/CtaFeature';
import ReactPlaceholder from 'react-placeholder';
import { TextBlockPlaceholder } from '../../components/placeholder/Placeholder';

const HomePage = () => {
  return (
    <div className="prixa-container is-top">
      <WelcomeMessage />
      {(process.env.REACT_APP_USER_ON === 'true' ? (
        true
      ) : (
        false
      )) ? (
        <CtaFeature isTitle={false} isShowDiagnonseMenu={true} />
      ) : (
        <span />
      )}
    </div>
  );
};

const WelcomeMessage = () => {
  const [name, setName] = useState();
  const [isTitleReady, setTitleReady] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      const profileDataLocalStorage = localStorage.getItem('profileData');
      const profileData = JSON.parse(String(profileDataLocalStorage));
      if (profileData !== null) {
        setName(profileData.name);
      }
    }, 500);
    if (name !== undefined) {
      setTitleReady(true);
      clearInterval(timer);
    }
  }, [name]);

  return (
    <div className="prixa-title margin-baseB">
      <ReactPlaceholder ready={isTitleReady} customPlaceholder={TextBlockPlaceholder}>
        <TitleSection name={name} />
      </ReactPlaceholder>
    </div>
  );
};

const TitleSection = ({ name }: any) => {
  return (
    <React.Fragment>
      <Text scale="question" style={{ color: '#4C4F54' }}>
        Hai, {name}!
      </Text>
      <br />
      <Text scale="content" style={{ color: '#AAAAAA' }}>
        Ada yang bisa Prixa bantu?
      </Text>
    </React.Fragment>
  );
};

export { HomePage };
