import React from 'react';
import { HomePage } from '../wrappers/user/HomePage';
import { InitiateChatPage } from '../wrappers/user/initiate-chat-page/InitiateChatPage';
import { VerificationCheckPage } from '../wrappers/user/VerificationCheckPage';
import { InitialPage } from '../wrappers/user/InitialPage';
import { ContinuePage } from '../wrappers/user/continue-page/ContinuePage';
import { ResetPasswordPage, ResetPasswordSuccessPage } from '../wrappers/user/reset-password-page/ResetPasswordPage';
import { VerificationSentPage } from '../wrappers/user/VerificationPage';
import { IntroPage } from '../wrappers/diagnostic/IntroPage';
import { Consent, ConsentAccept, ConsentRejectConfirmation, ConsentReject } from '../wrappers/diagnostic/ConsentPage';
import { ConversationPage } from '../wrappers/diagnostic/ConversationPage';
import { SetContainerProps } from '../wrappers/App';
import { ProfilePage } from '../wrappers/user/ProfilePage';
import { PersonalInformationPage } from '../wrappers/user/personal-information-page/PersonalInformationPage';
import { DiagnosisHistoryPage } from '../wrappers/user/diagnosis-history-page/DiagnosisHistoryPage';
import { ChangePasswordPage } from '../wrappers/user/change-password-page/ChangePasswordPage';

export const loggedURLs = (props: SetContainerProps) => [
  {
    path: '/home',
    exact: true,
    enable: process.env.REACT_APP_USER_ON === 'true' ? true : false,
    component: <HomePage />,
  },
  {
    path: '/initiate',
    exact: true,
    enable: process.env.REACT_APP_TELEMED_ON === 'true' ? true : false,
    component: <InitiateChatPage />,
  },
  {
    path: '/unverified',
    exact: true,
    enable: process.env.REACT_APP_USER_ON === 'true' ? true : false,
    component: <VerificationCheckPage />,
  },
  {
    path: '/profile',
    exact: true,
    enable: process.env.REACT_APP_USER_ON === 'true' ? true : false,
    component: <ProfilePage />,
  },
  {
    path: '/personal-information',
    exact: true,
    enable: process.env.REACT_APP_USER_ON === 'true' ? true : false,
    component: <PersonalInformationPage />,
  },
  {
    path: '/change-password',
    exact: true,
    enable: process.env.REACT_APP_USER_ON === 'true' ? true : false,
    component: <ChangePasswordPage />,
  },
  {
    path: '/diagnosis-history',
    exact: true,
    enable: process.env.REACT_APP_USER_ON === 'true' ? true : false,
    component: (
      <DiagnosisHistoryPage setHeader={props.setHeader} setPercentage={props.setPercentage} setError={props.setError} />
    ),
  },
];

export const regisSign = [
  {
    path: '/user',
    exact: true,
    enable: process.env.REACT_APP_USER_ON === 'true' ? true : false,
    component: <InitialPage />,
  },
  {
    path: '/continue',
    exact: true,
    enable: process.env.REACT_APP_USER_ON === 'true' ? true : false,
    component: <ContinuePage />,
  },
  {
    path: '/verification-sent',
    exact: true,
    enable: process.env.REACT_APP_USER_ON === 'true' ? true : false,
    component: <VerificationSentPage />,
  },
  {
    path: '/reset-password/:tokenPassword',
    exact: true,
    enable: process.env.REACT_APP_USER_ON === 'true' ? true : false,
    component: <ResetPasswordPage />,
  },
  {
    path: '/reset-password-sent',
    exact: true,
    enable: process.env.REACT_APP_USER_ON === 'true' ? true : false,
    component: <ResetPasswordSuccessPage />,
  },
  {
    path: '/verification-check/:tokenVerify',
    exact: true,
    enable: process.env.REACT_APP_USER_ON === 'true' ? true : false,
    component: <VerificationCheckPage />,
  },
];

export const diagnosisEngine = (props: SetContainerProps) => [
  {
    path: '/',
    exact: true,
    enable: true,
    component: <IntroPage />,
  },
  {
    path: '/consent',
    exact: true,
    enable: true,
    component: <Consent />,
  },
  {
    path: '/consent-reject-confirmation',
    exact: true,
    enable: true,
    component: <ConsentRejectConfirmation />,
  },
  {
    path: '/consent-reject',
    exact: true,
    enable: true,
    component: <ConsentReject />,
  },
  {
    path: '/conversation',
    exact: true,
    enable: true,
    component: (
      <ConversationPage setHeader={props.setHeader} setPercentage={props.setPercentage} setError={props.setError} />
    ),
  },
  {
    path: '/consent-accept',
    exact: true,
    enable: true,
    component: <ConsentAccept />,
  },
];
