import React from 'react';
import Routes from '../routes/routes';

export interface SetContainerProps {
  setHeader: (title?: string, subtitle?: string) => void;
  setPercentage: (percent?: number) => void;
  setError: (error?: string) => void;
}

const App: React.FC<SetContainerProps> = (props: SetContainerProps) => {
  return <Routes setHeader={props.setHeader} setPercentage={props.setPercentage} setError={props.setError} />;
};

export default App;
