export interface TabProps {
  name: string;
  option: string;
}

export const OPTION = {
  login: 'Masuk',
  signup: 'Daftar',
};

export const TAB = [
  { name: 'Masuk', option: OPTION.login },
  { name: 'Daftar', option: OPTION.signup },
];
