import React, { useEffect, useRef } from 'react';
import { FormLabel, InputText, Text, Textarea } from 'prixa-design-kit/dist';

export const useInput = ({
  label,
  type = 'text',
  rows,
  placeholder,
  errMessage,
  confirmWith,
  isValidate = true,
}: any) => {
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(false);
  const [validate, setValidate] = React.useState(false);

  const isFirstRun = useRef(true);
  /* eslint-disable */
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    const setErrorFalseAndValidateTrue = () => {
      setError(false);
      setValidate(true);
    };

    const setErrorTrueAndValidateFalse = () => {
      setError(true);
      setValidate(false);
    };

    if (isValidate) {
      if (type === 'text') {
        const isValidText = String(value).length > 0;
        isValidText ? setError(false) : setError(true);
      } else if (type === 'email') {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(value));
        isValidEmail ? setError(false) : setError(true);
      } else if (type === 'password') {
        const isValidPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(String(value));

        if (isValidPassword) {
          if (confirmWith) {
            if (value === confirmWith) {
              setErrorFalseAndValidateTrue();
              return;
            } else {
              setErrorTrueAndValidateFalse();
              return;
            }
          }
          setErrorFalseAndValidateTrue();
        } else {
          setErrorTrueAndValidateFalse();
        }
      }
    }
  }, [value]);
  /* eslint-enable */

  const input = (
    <React.Fragment>
      <FormLabel errors={false}>{label}</FormLabel>
      {type === 'textarea' ? (
        <Textarea
          errors={error}
          placeholder={placeholder}
          setData={setValue}
          small={false}
          validate={validate}
          value={value || ''}
          rows={rows || 2}
        />
      ) : (
        <InputText
          errors={error}
          placeholder={placeholder}
          setData={setValue}
          small={false}
          type={type}
          validate={validate}
          value={value || ''}
        />
      )}
      {errMessage && error ? (
        <div className="margin-microT">
          <Text scale="errorMessage">{errMessage}</Text>
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  );
  return { value, setValue, error, input };
};
