import React from 'react';
import { Text, Button, IconSolid, Paragraph, Card } from 'prixa-design-kit/dist';
import SideSheet, { openSideSheet } from '../../../components/sidesheet/SideSheet';
import SummarySideSheet from '../SummarySideSheet';
import EmailSideSheet from '../EmailSideSheet';
import SurveySideSheet from '../SurveySideSheet';
import ArticleSideSheet from '../ArticleSideSheet';
import { findInProfiles, getHighestDisease, getConvertedDisease } from './utils';
import { priority, triageMessage, classifyIconColor, classifyTextColor } from './constant';
import { getAPI } from '../../../api/api-method';
import { DIAGNOSTIC_API } from '../../../api/api-url';
import CtaFeature from '../../../components/cta-feature/CtaFeature';

const ResultPage = (props: any) => {
  const { user, userDetails, profiles, symptoms, diseases } = props.diagnoseResult;

  const highestDisease = getHighestDisease(diseases);
  const summaryMessage = highestDisease.triage === priority.homecare ? triageMessage.homecare : triageMessage.opd;

  const disclaimerProps = {
    user: user,
    profiles: profiles,
    symptoms: symptoms,
    userDetails: userDetails,
    sessionId: props.sessionId,
  };

  return (
    <div className="prixa-container is-top is-full">
      <Summary summaryMessage={summaryMessage} />
      <ResultList convertedDisease={getConvertedDisease(diseases)} />
      <Disclaimer {...disclaimerProps} />
      {(process.env.REACT_APP_USER_ON === 'true' ? (
        true
      ) : (
        false
      )) ? (
        <div className="margin-large">
          <CtaFeature />
        </div>
      ) : (
        <span />
      )}
      <Finish sessionId={props.sessionId} />
    </div>
  );
};

const Summary = ({ summaryMessage }: any) => {
  return (
    <div className="prixa-container" style={{ margin: '20px 40px' }}>
      <Paragraph scale="pagesubtitle">RINGKASAN</Paragraph>
      <Paragraph>
        <b>{summaryMessage} </b>
        Lihat keterangan lebih lanjut pada hasil prediksi di bawah untuk informasi penanganan yang dapat Anda lakukan.
      </Paragraph>
    </div>
  );
};

const ResultList = ({ convertedDisease }: any) => {
  const [modalArticle, setModalArticle] = React.useState(false);
  const [article, setArticle] = React.useState();
  const [articleTitle, setArticleTitle] = React.useState();

  const viewArticle = (id: string, name: string, url: string) => {
    getAPI(DIAGNOSTIC_API.DISEASE_ARTICLE(id)).then(async (res: any) => {
      const articleLink = { url: url };
      const articleData = { ...res, ...articleLink };
      setArticleTitle(name);
      setArticle(articleData);
      setModalArticle(true);
      openSideSheet();
    });
  };

  return (
    <div className="prixa-list-container">
      {convertedDisease.map((disease: any, i: number) => {
        return (
          <Card key={i} style={{ padding: '16px', margin: '20px 40px' }}>
            <div className="prixa-list-title">
              <Text scale="question">{disease.name}</Text>
            </div>
            <div>
              <IconSolid
                backgroundColor={classifyIconColor[disease.classify]}
                backgroundSize="18px"
                color="white"
                margin="0px"
                type="faPercentage"
                width="8px"
              >
                <Text className="margin-smallB margin-microL" scale={classifyTextColor[disease.classify]}>
                  {disease.evidenceLevel}
                </Text>
              </IconSolid>
            </div>
            {disease.description ? (
              <div className="prixa-list-subtitle">
                <Text scale="content">{disease.description}</Text>
              </div>
            ) : (
              ''
            )}
            {disease.article !== '#' ? (
              <span className="prixa-list-button">
                <Button
                  onClick={() => viewArticle(disease.id, disease.name, disease.article)}
                  size="small"
                  variant="outline"
                >
                  Lebih Lanjut
                </Button>
              </span>
            ) : (
              ''
            )}
          </Card>
        );
      })}
      <SideSheet
        setModal={setModalArticle}
        show={modalArticle}
        title={articleTitle}
        content={<ArticleSideSheet {...article}></ArticleSideSheet>}
      />
    </div>
  );
};

const Disclaimer = (props: any) => {
  const { user, profiles, symptoms, userDetails, sessionId } = props;
  const [modalSummary, setModalSummary] = React.useState(false);
  const [modalEmail, setModalEmail] = React.useState(false);
  const gender = findInProfiles(profiles, 'gender');
  const age = userDetails.ageYear ? userDetails.ageYear + ' tahun ' : '';
  const month = userDetails.ageMonth ? userDetails.ageMonth + ' bulan' : '';
  const ageResult = age + month;

  return (
    <React.Fragment>
      <Card className="prixa-result-content text-center margin-largeX margin-largeB">
        <Text style={{ color: '#4C4F54', paddingTop: '26px' }}>
          <b>Perlu diingat, Hasil Prixa ini bukan pengganti diagnosis medis dokter ya. </b>
        </Text>
        <Text className="margin-baseB" style={{ color: '#4C4F54' }}>
          Segera konsultasi dengan dokter jika keluhan memburuk atau terdapat keluhan lain yang Anda rasakan.
        </Text>
        <span
          onClick={() => {
            setModalSummary(true);
            openSideSheet();
          }}
        >
          <Button
            style={{ color: '#0146AB', borderColor: '#0146AB' }}
            size="large"
            variant="outline"
            className="margin-smallB"
          >
            Lihat Rangkuman Keluhan
          </Button>
        </span>
        <span
          onClick={() => {
            setModalEmail(true);
            openSideSheet();
          }}
        >
          <Button
            style={{ color: '#0146AB', borderColor: '#0146AB', marginBottom: '40px' }}
            size="large"
            variant="outline"
          >
            Kirim Hasil Prixa ke Email
          </Button>
        </span>
      </Card>
      <SideSheet
        setModal={setModalSummary}
        show={modalSummary}
        title="Rangkuman Keluhan"
        subtitle={gender + ', ' + ageResult}
        content={<SummarySideSheet user={user} profiles={profiles} symptoms={symptoms} userDetails={userDetails} />}
      />
      <SideSheet
        setModal={setModalEmail}
        show={modalEmail}
        title="Kirim Hasil Prixa"
        content={<EmailSideSheet sessionId={sessionId}></EmailSideSheet>}
      />
    </React.Fragment>
  );
};

const Finish = ({ sessionId }: any) => {
  const [modalSurvey, setModalSurvey] = React.useState(false);

  return (
    <React.Fragment>
      <span
        onClick={() => {
          setModalSurvey(true);
          openSideSheet();
        }}
      >
        <Button size="full" variant="primary">
          Selesai
        </Button>
      </span>
      <SideSheet
        setModal={setModalSurvey}
        show={modalSurvey}
        title="Survey Layanan"
        content={<SurveySideSheet sessionId={sessionId}></SurveySideSheet>}
      />
    </React.Fragment>
  );
};

export { ResultPage };
