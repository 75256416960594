import React from 'react';
import '../sidesheet/sidesheet.scss';
import { Text, Icon } from 'prixa-design-kit/dist';
import { CSSTransition } from 'react-transition-group';

const imgHeader = `${process.env.REACT_APP_ASSET_URL}/prixa-header.png`;

interface SideSheetProps {
  title?: string;
  subtitle?: string;
  setModal?: any;
  show: boolean;
  content: any;
}

const SideSheet: any = (props: SideSheetProps) => {
  return (
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={1000}
      classNames={{
        enter: 'animated',
        enterActive: 'slideInRight faster',
        exit: 'animated',
        exitActive: 'slideOutRight faster',
      }}
    >
      <div className="prixa-sidesheet">
        <div className="prixa-header">
          <div className="prixa-caption">
            <Text scale="headerTitle">
              {(() => {
                if (props.title) {
                  return props.title;
                } else {
                  return <img loading="lazy" alt="logo" width="50px" className="prixa-header-image" src={imgHeader} />;
                }
              })()}
            </Text>
            {(() => {
              if (props.subtitle) {
                return <Text scale="headerSubtitle">{props.subtitle}</Text>;
              }
            })()}
          </div>
          <div className="prixa-menubar">
            <span
              onClick={() => {
                props.setModal(false);
                const scrollY = document.body.style.top;
                document.body.style.position = '';
                document.body.style.top = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
              }}
            >
              <Icon color="dark" type="faTimes" width="16px" />
            </span>
          </div>
        </div>
        <div className="prixa">{props.content}</div>
      </div>
    </CSSTransition>
  );
};

const openSideSheet: any = () => {
  document.body.style.top = `-${window.scrollY}px`;
  document.body.style.position = 'fixed';
  document.body.style.width = '100%';
};

export default SideSheet;
export { openSideSheet };
