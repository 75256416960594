import React from 'react';
import { Text, Box, Button, InputText } from 'prixa-design-kit/dist';
import CtaFeature from '../../components/cta-feature/CtaFeature';

interface Article {
  id: string;
  diseaseName: string;
  diseaseNameIndo: string;
  author: string;
  checkedBy: string;
  overview: string;
  advice: any;
  seeDoctor: string;
  prevention: string;
  reference: string;
  relatedSymptom: Array<string>;
  url: string;
}

const ArticleSideSheet = (props: Article) => {
  const [copyLabel, setCopyLabel] = React.useState('Salin');

  const copyText = (link: string) => {
    const copyText = document.getElementById(link) as HTMLInputElement;
    if (copyText) {
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand('copy');
      setCopyLabel('Disalin!');
      setTimeout(() => {
        setCopyLabel('Salin');
      }, 1500);
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      <div className="prixa-container is-top">
        <span>
          <Text scale="headerSubtitle">Ditulis oleh {props.author}</Text>
        </span>
        <br />
        <span>
          <Text scale="headerSubtitle">Diperiksa oleh {props.checkedBy}</Text>
        </span>
        <div className="margin-baseY">
          <Text scale="content">
            {props.overview.split('\n').map((item: any, key: number) => {
              return (
                <React.Fragment key={key}>
                  {item}
                  <br />
                </React.Fragment>
              );
            })}
          </Text>
        </div>
        <div>
          <Text scale="pageTitle">Kenapa Anda mendapat hasil ini?</Text>
        </div>
        <div className="margin-baseY">
          <Text scale="content">Anda memiliki satu atau lebih keluhan berikut:</Text>
          {props.relatedSymptom.map((symptom: string, i: number) => {
            return (
              <li key={i}>
                <Text scale="content">{symptom}</Text>
              </li>
            );
          })}
        </div>
        <div>
          <Text scale="pageTitle">Apa yang bisa Anda lakukan?</Text>
        </div>
        <div className="margin-baseY">
          <Text scale="content">
            {props.advice.split('\n').map((item: any, key: number) => {
              return (
                <React.Fragment key={key}>
                  {item}
                  <br />
                </React.Fragment>
              );
            })}
          </Text>
        </div>
        <div>
          <Text scale="pageTitle">Kapan Anda perlu ke dokter?</Text>
        </div>
        <div className="margin-baseY">
          <Text scale="content">
            {props.seeDoctor.split('\n').map((item: any, key: number) => {
              return (
                <React.Fragment key={key}>
                  {item}
                  <br />
                </React.Fragment>
              );
            })}
          </Text>
        </div>
        <div>
          <Text scale="pageTitle">Bagaimana pencegahannya?</Text>
        </div>
        <div className="margin-baseY">
          <Text scale="content">
            {props.prevention.split('\n').map((item: any, key: number) => {
              return (
                <React.Fragment key={key}>
                  {item}
                  <br />
                </React.Fragment>
              );
            })}
          </Text>
        </div>
        <div>
          <Text scale="pageTitle">Referensi</Text>
        </div>
        <div className="margin-baseT">
          <Text scale="caption">
            {props.reference.split('\n').map((item: any, key: number) => {
              return (
                <Text key={key}>
                  {item}
                  <br />
                </Text>
              );
            })}
          </Text>
        </div>
        <div className="margin-baseY">
          <Box variant="default">
            <div className="text-center padding-small">
              <div className="padding-smallB">
                <Text scale="pageTitle" style={{ color: 'white' }}>
                  Ingin tahu informasi lebih lanjut tentang penyakit ini?
                </Text>
              </div>
              <div>
                <Button
                  href={props.url}
                  size="large"
                  target="_blank"
                  variant="outline"
                  style={{ color: 'white', borderColor: 'white' }}
                >
                  Baca Selengkapnya
                </Button>
              </div>
            </div>
          </Box>
        </div>
        <div className="margin-largeY">
          <Box variant="secondary">
            <div className="text-center padding-small">
              <div className="padding-smallB">
                <Text scale="pageTitle" style={{ color: 'white' }}>
                  Bagikan artikel ini:
                </Text>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <InputText
                  errors={false}
                  small={false}
                  validate={false}
                  value={props.url}
                  className="margin-tinyR"
                  id="article-link"
                />
                <Button
                  size="tiny"
                  variant="outline"
                  style={{ color: 'white', borderColor: 'white' }}
                  onClick={() => copyText('article-link')}
                >
                  {copyLabel}
                </Button>
              </div>
            </div>
          </Box>
        </div>
        {(process.env.REACT_APP_USER_ON === 'true' ? true : false) ? <CtaFeature /> : <span />}
      </div>
    </React.Fragment>
  );
};

export default ArticleSideSheet;
