import React, { useEffect, useState } from 'react';
import Conversation from '../../components/conversation/Conversation';
import { getConversation, sessionId, geoLocation, GeoLocationType } from '../../api/api-utils';
import { ResultPage } from './result-page/ResultPage';
import { ChiefComplaintNotFound } from './ChiefComplaintPage';
import { SetContainerProps } from '../App';
import { StatusPrecondition } from '../../components/conversation/StatusPrecondition';
import { BasicPrecondition } from '../../components/conversation/BasicPrecondition';
import { ChiefComplaintResult } from './ChiefComplaintPage';
import { LoadPage } from './LoadPage';

export interface PreconReply {
  id?: string;
  ageMonth?: number;
  ageYear?: number;
  height?: number;
  width?: number;
  type: string;
  preconditionsDescription?: string;
}

export interface Option {
  type: string;
  text: string;
  reply?: {
    type?: string;
    value?: any;
    tag?: string;
    list?: string;
    precondition?: Array<PreconReply>;
  };
  variant: 'default' | 'outline' | 'primary' | 'disabled' | 'secondary' | 'success' | undefined;
}

const ConversationPage: React.FC<SetContainerProps> = (props: SetContainerProps) => {
  const [{ title, information, options, list, imgSrc }, setData] = useState({
    title: [],
    information: null,
    options: [],
    list: [],
    imgSrc: '',
    progress: 0,
    currState: '',
  });
  const [diagnoseResult, setDiagnoseResult] = useState(null);
  const [symptomID, setSymptomID] = useState(null);
  const [load, setLoad] = useState(true);
  const [{ state }, setState] = useState({ state: '' });
  const [search, setSearchQuery] = React.useState('');
  const [preconditionData, setPreconditionData] = useState([{}, {}]);

  function doSetError(error?: string) {
    props.setError(error || '');
  }

  async function CallConverse(reply?: Option['reply']): Promise<any> {
    setLoad(true);
    let data: any;

    if (state === 'initial') {
      data = {
        progress: 15,
        title: ['Untuk siapa pemeriksaan ini dilakukan?'],
        options: [
          {
            text: 'Diri Sendiri',
            type: 'button',
            variant: 'secondary',
            reply: {
              label: 'Lanjutkan',
              value: 'true',
              type: 'button',
              tag: 'Diri Sendiri',
            },
          },
          {
            text: 'Orang Lain',
            type: 'button',
            variant: 'secondary',
            reply: {
              label: 'Lanjutkan',
              value: 'true',
              type: 'button',
              tag: 'Orang Lain',
            },
          },
        ],
      };
    } else if (reply && reply.tag === 'ComplaintNotInOptions') {
      data = {
        title: ['Not Found'],
        options: [
          {
            text: '',
            variant: '',
            type: 'list',
            reply: {
              type: '',
              value: '',
              tag: '',
              list: '',
            },
          },
        ],
      };
    } else if (reply && reply.value === 'Lanjutkan' && state === 'diagnosis') {
      data = {
        progress: 100,
        title: [
          'Perlu diingat, hasil Prixa ini bukan pengganti diagnosis medis dokter ya.',
          'Konsultasikan keluhan Anda dengan dokter untuk pemeriksaan lebih lanjut.',
        ],
        options: [
          {
            text: 'Lihat Hasil',
            type: 'button',
            variant: 'primary',
            reply: {
              value: '',
              tag: 'Lihat Hasil',
            },
          },
        ],
      };
    } else if (reply && reply.tag === 'Lihat Hasil') {
      data = {
        title: ['Result'],
        options: [
          {
            text: '',
            variant: '',
            type: 'list',
            reply: {
              type: '',
              value: '',
              tag: '',
              list: reply.list,
            },
          },
        ],
      };
    } else {
      try {
        if (navigator.geolocation && geoLocation.latitude === 0 && geoLocation.longitude === 0) {
          navigator.geolocation.getCurrentPosition(position => {
            geoLocation.latitude = position.coords.latitude;
            geoLocation.longitude = position.coords.longitude;
          });
        }

        data = await getConversation({ reply, geoLocation });
        if (data.diagnoseResult) {
          if (localStorage.getItem('sesId')) {
            localStorage.setItem('diagnoseResult', JSON.stringify(data.diagnoseResult));
          }
        }
        setDiagnoseResult(data.diagnoseResult);
        setSymptomID(data.symptomID);
      } catch (error) {
        doSetError('Maaf, ada kesalahan dari kami. Silahkan muat ulang halaman anda.');
        setLoad(false);
        data = {
          imgSrc: '',
          title: '',
          information: '',
          options: '',
          list: '',
          progress: '',
          currState: '',
        };
      }
    }

    setData({
      imgSrc: data.imgSrc || undefined,
      title: data.title,
      information: data.information,
      options: data.options,
      list: data.list,
      progress: data.progress || 0,
      currState: data.state || '',
    });
    setLoad(false);
    props.setPercentage(data.progress || 0);
    setState({ state: data.state || '' });
    return data.state || '';
  }

  /*eslint-disable */
  useEffect(() => {
    setLoad(true);
    let data: any;

    const initConversation = (geoLocation: GeoLocationType) => {
      getConversation({ geoLocation })
        .then(res => {
          data = res;
          setData({
            imgSrc: data.imgSrc,
            title: data.title,
            information: data.information,
            options: data.options,
            list: [],
            progress: data.progress || 0,
            currState: data.state || '',
          });
          setState({ state: data.state });
          setLoad(false);
          props.setPercentage(data.progress || 0);
        })
        .catch(() => {
          doSetError('Maaf, ada kesalahan dari kami');
          setLoad(false);
        });
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          geoLocation.latitude = position.coords.latitude;
          geoLocation.longitude = position.coords.longitude;
          initConversation(geoLocation);
        },
        () => initConversation(geoLocation),
      );
    } else {
      initConversation(geoLocation);
    }
  }, []);
  /*eslint-enable */

  useEffect(() => {
    if (state === 'askPrecondition') {
      props.setPercentage(25);
    } else if (state === 'askPrecondition1') {
      props.setPercentage(45);
    }
  });

  if (load && state === 'askChiefComplaint') {
    return <LoadPage />;
  } else if (state === 'showComplaint') {
    return (
      <ChiefComplaintResult
        search={search}
        callFunction={CallConverse}
        title={title}
        loading={load}
        options={options}
      />
    );
  } else if (state === 'askPrecondition') {
    return (
      <BasicPrecondition
        preconditionData={preconditionData}
        setPreconditionData={setPreconditionData}
        callFunction={CallConverse}
        title={title}
        loading={load}
        list={list}
        setConvoState={setState}
      />
    );
  } else if (state === 'askPrecondition1') {
    return (
      <StatusPrecondition
        preconditionData={preconditionData}
        setPreconditionData={setPreconditionData}
        callFunction={CallConverse}
        title={title}
        loading={load}
        list={list}
        setConvoState={setState}
      />
    );
  } else if (title[0] === 'Not Found' || title[0] === 'Maaf kami belum dapat melakukan diagnosa penyakit tersebut') {
    return <ChiefComplaintNotFound></ChiefComplaintNotFound>;
  } else if (title[0] === 'Result') {
    const sessId = localStorage.getItem('sesId');
    const dResult = localStorage.getItem('diagnoseResult');
    if (sessId && dResult && !diagnoseResult) {
      const diagResult = JSON.parse(dResult);
      setDiagnoseResult(diagResult);
    }
    localStorage.setItem('DiagnosisID', sessionId);
    return <ResultPage sessionId={sessionId} diagnoseResult={diagnoseResult}></ResultPage>;
  } else {
    return (
      <Conversation
        title={title}
        information={information}
        options={options}
        callFunction={CallConverse}
        loading={load}
        imgSrc={imgSrc}
        search={search}
        setSearchQuery={setSearchQuery}
        state={state}
        sessionId={sessionId}
        symptomID={symptomID}
      ></Conversation>
    );
  }
};

export { ConversationPage };
