import { postAPI } from '../api/api-method';
import { DIAGNOSTIC_API } from '../api/api-url';

const complaint = `${process.env.REACT_APP_ASSET_URL}/illustration/complaint.png`;

export interface GeoLocationType {
  latitude: number;
  longitude: number;
}

export let sessionId = 'none';
export const geoLocation: GeoLocationType = {
  latitude: 0,
  longitude: 0,
};

export function getConversation(params: { reply?: object; geoLocation?: GeoLocationType }) {
  let reqData = {};

  if (sessionId === 'none') {
    const storeSessionId = localStorage.getItem('sesId');

    if (storeSessionId) {
      sessionId = storeSessionId;
    }

    reqData = {
      sessionId: sessionId,
      reply: { type: 'resume' },
      geoLocation: params.geoLocation || geoLocation,
    };
  } else {
    reqData = {
      sessionId: sessionId,
      reply: params.reply,
      geoLocation: params.geoLocation || geoLocation,
    };
  }

  return new Promise(async (resolve, reject) => {
    try {
      const data: any = await postAPI(DIAGNOSTIC_API.CONVERSATION, reqData);

      let imgSrc;

      const diagnoseResult = data.result.actions.diagnosisResult;
      const symptomID = data.result.symptomID;
      sessionId = data.sessionId;

      if (data.sessionId && data.progressBarPercentage > 20) {
        localStorage.setItem('sesId', data.sessionId);
      }

      const title = data.result.messages.value.split('. ').map((text: string, key: any) => {
        if (key < data.result.messages.value.split('. ').length - 1) {
          return text + '.';
        } else {
          return text;
        }
      });

      const information = data.result.messages.explanation;

      if (data.result.currentState === 'askChiefComplaint') {
        imgSrc = complaint;
      }

      const options = () => {
        let buttonText: string | undefined;
        const state = 'initialUserInfo, askPrecondition, diagnosis';

        if (state.includes(data.result.currentState)) {
          buttonText = 'Lanjut';
        } else {
          buttonText = undefined;
        }

        if (data.result.actions.type === 'text') {
          return [
            {
              type: 'text',
              text: data.result.actions.value,
              variant: undefined,
              reply: {
                type: 'text',
                value: undefined,
                tag: undefined,
              },
            },
          ];
        } else if (data.result.currentState === 'diagnosis') {
          return [
            {
              type: 'button',
              text: buttonText,
              variant: 'primary',
              reply: {
                type: 'button',
                value: String('Lanjutkan'),
              },
            },
          ];
        } else if (data.result.actions.type === 'button') {
          return data.result.actions.value.map((item: any) => {
            return {
              type: data.result.actions.type,
              text: buttonText || item.label,
              variant: data.result.actions.value.length === 1 ? 'primary' : 'secondary',
              description: item.description || '-',
              reply: {
                type: data.result.actions.type,
                value: String(item.value),
                tag: item.tag,
              },
            };
          });
        } else if (data.result.actions.type === 'Preconditions') {
          return data.result.preconditions;
        } else {
          return [];
        }
      };

      let progress: number;

      if (data.result.currentState === 'preChiefComplaint') {
        progress = 100;
      } else if (data.progressBarPercentage === 20 && data.result.currentState === 'askInsurance') {
        progress = 57;
      } else if (data.progressBarPercentage === 24 && data.result.currentState === 'askInsurance') {
        progress = 80;
      } else {
        progress = data.progressBarPercentage;
      }

      const returnData = {
        sessionId: data.sessionId,
        progress: progress || 0,
        state: data.result.currentState,
        list: data.result.preconditions || undefined,
        title,
        imgSrc,
        options: options(),
        information,
        diagnoseResult,
        symptomID,
      };
      resolve(returnData);
    } catch (error) {
      reject(error);
    }
  });
}

export function getNewSession() {
  return new Promise(async (resolve, reject) => {
    try {
      const data: any = await postAPI(DIAGNOSTIC_API.CONVERSATION, {});
      resolve(data.sessionId);
    } catch (error) {
      reject(error);
    }
  });
}
