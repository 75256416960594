import React, { useEffect, useState } from 'react';
import { VerifiedPage, UnverifiedPage } from '../user/VerificationPage';
import { postAPI } from '../../api/api-method';
import { USER_API } from '../../api/api-url';
import { LoadPage } from '../../wrappers/diagnostic/LoadPage';
import { ErrorPage } from '../../wrappers/diagnostic/ErrorPage';

const VerificationCheckPage = () => {
  const [isVerified, setIsVerified] = useState();

  /* eslint-disable */
  const state = {
    LOAD: <LoadPage />,
    ERROR: <ErrorPage text="Link yang anda masukan salah" />,
    VERIFIED: <VerifiedPage />,
    UNVERIFIED: <UnverifiedPage email="Anda" />,
  };

  useEffect(() => {

    const token = String(window.location.pathname).split('verification-check/')[1];

    if (token) {
      postAPI(USER_API.REGIS_VERIFY(token), {
        registerToken: token,
        diagnosticSessionID: localStorage.getItem('DiagnosisID'),
      })
        .then(async (res: any) => {
          setIsVerified(state.VERIFIED);
        })
        .catch(() => {
          setIsVerified(state.ERROR);
        });
    }
    else {
      setIsVerified(state.UNVERIFIED);
    }
  }, []);
  /* eslint-enable */

  return isVerified || state.LOAD;
};

export { VerificationCheckPage };
