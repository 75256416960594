export const urlValidation = () => {
  try {
    if (
      window.location.search === '' &&
      window.location.pathname.includes('partner/') &&
      window.location.pathname.includes('/app/')
    ) {
      return {
        partnerId: String(window.location.pathname.split('partner/')[1].split('/app/')[0]),
        appId: String(window.location.pathname.split('/app/')[1]),
      };
    } else if (
      window.location.search !== '' &&
      window.location.search.includes('pId=') &&
      window.location.search.includes('&appId=')
    ) {
      return {
        partnerId: String(window.location.search.split('pId=')[1].split('&ap')[0]),
        appId: String(window.location.search.split('&appId=')[1]),
      };
    } else if (
      localStorage.getItem('getPartnerID') &&
      localStorage.getItem('getAppID') &&
      localStorage.getItem('getPartnerID') !== '' &&
      localStorage.getItem('getAppID') !== ''
    ) {
      return {
        partnerId: String(localStorage.getItem('getPartnerID')),
        appId: String(localStorage.getItem('getAppID')),
      };
    } else {
      return { partnerId: '', appId: '' };
    }
  } catch {
    return { partnerId: '', appId: '' };
  }
};

export const getPartnerID = urlValidation().partnerId;

export const getAppID = urlValidation().appId;
