import React from 'react';
import 'react-placeholder/lib/reactPlaceholder.css';
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders';

const TextBlockPlaceholder = (
  <React.Fragment>
    <TextBlock rows={3} color="rgba(170, 170, 170, 0.24)" />
  </React.Fragment>
);

const RectShapePlaceholder = (
  <React.Fragment>
    <RectShape style={{ width: '100%', height: '80px', borderRadius: '8px' }} color="rgba(170, 170, 170, 0.24)" />
  </React.Fragment>
);

export { TextBlockPlaceholder, RectShapePlaceholder };
