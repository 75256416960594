import React from 'react';
import '../carousel/carousel.scss';
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Text } from 'prixa-design-kit/dist';

const carouselHome = `${process.env.REACT_APP_ASSET_URL}/carousel/prixa-app-intro.png`;
const carouselKeluhan = `${process.env.REACT_APP_ASSET_URL}/carousel/home-keluhan.png`;
const carouselPertanyaan = `${process.env.REACT_APP_ASSET_URL}/carousel/home-pertanyaan.png`;
const carouselPrediksi = `${process.env.REACT_APP_ASSET_URL}/carousel/home-prediksi.png`;
const carouselPencegahan = `${process.env.REACT_APP_ASSET_URL}/carousel/home-pencegahan.png`;
const carouselKapanpun = `${process.env.REACT_APP_ASSET_URL}/carousel/home-kapanpun.png`;

const CustomDot = ({ onClick, active }: any) => {
  return <button className={active ? 'carousel-dot active' : 'carousel-dot'} onClick={() => onClick()}></button>;
};

const Carousel: React.FC = () => {
  return (
    <MultiCarousel
      additionalTransfrom={0}
      arrows={false}
      autoPlay
      autoPlaySpeed={3000}
      centerMode={false}
      className=""
      containerClass="container"
      customDot={<CustomDot />}
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={true}
      renderDotsOutside
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 1,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 1,
        },
      }}
      showDots
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >
      <div className="animated fadeInUp">
        <div
          style={{
            width: '100%',
            height: '250px',
            margin: 'auto',
            backgroundImage: `url(${carouselHome})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
          }}
        ></div>
        <br />
        <div className="margin-baseY prixa-title">
          <Text scale="heroTitle">Halo, ini sistem periksa kesehatan tepat berbasis AI Prixa.</Text>
        </div>
      </div>
      <div>
        <div
          style={{
            width: '100%',
            height: '250px',
            margin: 'auto',
            backgroundImage: `url(${carouselKeluhan})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
          }}
        ></div>
        <br />
        <div className="margin-baseY prixa-title">
          <Text scale="heroTitle">Sistem kami dapat mendeteksi keluhan kesehatan yang Anda alami,</Text>
        </div>
      </div>
      <div>
        <div
          style={{
            width: '100%',
            height: '250px',
            margin: 'auto',
            backgroundImage: `url(${carouselPertanyaan})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
          }}
        ></div>
        <br />
        <div className="margin-baseY prixa-title">
          <Text scale="heroTitle">mengumpulkan informasi kesehatan terkait keluhan Anda,</Text>
        </div>
      </div>
      <div>
        <div
          style={{
            width: '100%',
            height: '250px',
            margin: 'auto',
            backgroundImage: `url(${carouselPrediksi})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
          }}
        ></div>
        <br />
        <div className="margin-baseY prixa-title">
          <Text scale="heroTitle">memberi hasil prediksi kemungkinan kondisi, dan…</Text>
        </div>
      </div>
      <div>
        <div
          style={{
            width: '100%',
            height: '250px',
            margin: 'auto',
            backgroundImage: `url(${carouselPencegahan})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
          }}
        ></div>
        <br />
        <div className="margin-baseY prixa-title">
          <Text scale="heroTitle">menginformasikan penanganan yang dapat Anda lakukan.</Text>
        </div>
      </div>
      <div>
        <div
          style={{
            width: '100%',
            height: '250px',
            margin: 'auto',
            backgroundImage: `url(${carouselKapanpun})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
          }}
        ></div>
        <br />
        <div className="margin-baseY prixa-title">
          <Text scale="heroTitle">Kapan pun, di mana pun. Jadi, ada yang bisa Prixa bantu?</Text>
        </div>
      </div>
    </MultiCarousel>
  );
};

export default Carousel;
