import React from 'react';
import { Text, Paragraph, Button } from 'prixa-design-kit/dist';
import CheckBox from '../../components/checkbox/CheckBox';
import TextArea from '../../components/textarea/TextArea';
import { postAPI } from '../../api/api-method';
import { DIAGNOSTIC_API } from '../../api/api-url';
import { Icon } from 'prixa-design-kit/dist';
import { firstLetterToUpperCase } from '../../utils/stringFunction';

const getPartnerID = window.location.search
  ? window.location.search.split('pId=')[1].split('&ap')[0]
  : `${process.env.REACT_APP_PARTNER_ID || ''}`;
const getAppID = window.location.search
  ? window.location.search.split('&appId=')[1]
  : `${process.env.REACT_APP_APP_ID || ''}`;

interface QuestionFeedback {
  question: string | Array<string>;
  instruction: string;
  choices: Array<string>;
  sessionId: string;
  title: Array<string>;
  symptomID: string;
  setModal: any;
  setToast: any;
  setToastVariant: any;
}

interface FeedbackProps {
  userId: string;
  partnerId: string;
  applicationId: string;
  sessionId: string;
  symptomId: string;
  question: string;
  feedbacks: Array<string>;
  detail: string;
}

const QuestionFeedbackSideSheet = (props: QuestionFeedback) => {
  const [detail, setDetail] = React.useState('');
  const [choices, setChoices] = React.useState();
  const [sent, setSent] = React.useState(true);

  const sendFeedback = () => {
    setSent(false);

    const data: FeedbackProps = {
      userId: '',
      partnerId: getPartnerID,
      applicationId: getAppID,
      sessionId: props.sessionId,
      symptomId: props.symptomID,
      question: props.title.join(''),
      feedbacks: choices,
      detail: detail,
    };
    postAPI(DIAGNOSTIC_API.FEEDBACK, data)
      .then(async (res: any) => {
        props.setToastVariant('success');
        props.setToast('Feedback Anda berhasil terkirim.');
        setTimeout(() => {
          props.setToast('');
        }, 3000);
        setSent(true);
        props.setModal(false);
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      })
      .catch(err => {
        props.setToastVariant('error');
        props.setToast(firstLetterToUpperCase(err.response.data.error));
        setTimeout(() => {
          props.setToast('');
        }, 3000);
        setSent(true);
      });
  };

  const checkedOption = (choice: string) => {
    if (!choices) {
      setChoices([choice]);
    } else if (!choices.includes(choice)) {
      setChoices(choices.concat(choice));
    } else {
      const choiceFilter = choices.filter((choiceFilter: string) => choiceFilter !== choice);
      setChoices(choiceFilter);
    }
  };

  return (
    <React.Fragment>
      <div className="prixa-container is-top is-full-height">
        <div className="prixa-question-section margin-baseB">
          <Text scale="heroTitle">{props.question}</Text>
        </div>
        <div className="margin-baseB">
          <Paragraph scale="caption">{props.instruction}</Paragraph>
        </div>
        {props.choices.map((choice, i) => {
          return <CheckBox key={i} label={choice} value={choice} onChange={() => checkedOption(choice)}></CheckBox>;
        })}
        <div className="margin-largeY">
          <TextArea setData={setDetail} placeholder="Silahkan tulis feedback Anda"></TextArea>
        </div>
      </div>
      <div className="prixa-footer-button">
        <Button onClick={() => sendFeedback()} size="full" variant="primary" disabled={!sent}>
          {sent ? (
            'Kirim & Kembali ke Pertanyaan'
          ) : (
            <div style={{ width: '20px', margin: 'auto' }}>
              <Icon color="light" type="faCircleNotch" spin />
            </div>
          )}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default QuestionFeedbackSideSheet;
