const BASE = `${process.env.REACT_APP_API_URL}/v1`;
const PRIXA_BASE = `${BASE}/prixa`;
const PARTNER_BASE = `${BASE}/partner`;
const USER_BASE = `${BASE}/user`;
const TELEMED_BASE = `${BASE}/telemedicine`;

export const DIAGNOSTIC_API = {
  CONVERSATION: `${process.env.REACT_APP_API_URL}/v2/prixa`, //post
  USER: `${PRIXA_BASE}/user`, //post
  SEND_EMAIL: `${BASE}/conversation/mail/send`, //post
  SEND_SURVEY: `${PRIXA_BASE}/survey`, //post
  FEEDBACK: `${PRIXA_BASE}/feedback`, //get //post
  DISEASE_ARTICLE: (diseaseId: string) => `${BASE}/disease/article/${diseaseId}`, //get
};

export const EMAIL_API = {
  EMAIL_DIAGNOSTIC_RESULT: `${PRIXA_BASE}/email/send`, //post
};

export const PARTNER_API = {
  PARTNER: `${PARTNER_BASE}`, //post //get
  PARTNER_ID: (partnerId: string) => `${PARTNER_BASE}/${partnerId}`, //post //put //get
};

export const PARTNER_APP_API = {
  PARTNER_APP: (partnerId: string) => `${PARTNER_BASE}/${partnerId}/application`, //post //get
  PARTNER_APP_ID: (partnerId: string, applicationId: string) =>
    `${PARTNER_BASE}/${partnerId}/application/${applicationId}`, //put //delete //get
};

export const USER_API = {
  LOGIN: `${USER_BASE}/login`, //post
  CONSENT: `${USER_BASE}/consent`, //get
  REGISTER: `${USER_BASE}/register`, //post
  REGIS_VERIFY: (tokenRegister: string) => `${USER_BASE}/register/${tokenRegister}/verify`, //post
  FORGET_PASSWORD: `${USER_BASE}/forget-password`, //post //put
  FORGET_VERIFY: (tokenForgetPwd: string) => `${USER_BASE}/forget-password/${tokenForgetPwd}/verify`, //post
  INFO: `${BASE}/userinfo`, //get
  CHANGE_PASSWORD: `${BASE}/change-password`, //post
  LOGOUT: `${BASE}/logout`, //get
  RESEND_EMAIL: `${USER_BASE}/resend-email`, //get
  UPDATE: `${USER_BASE}/profile/update`, //post
  UPLOAD_URL: `${BASE}/user/signedurl`, //post
};

export const TELEMED_API = {
  INIT_CONVO: `${TELEMED_BASE}/conversation/init`, //post
};
