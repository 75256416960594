import React, { useState, useEffect } from 'react';
import { Button, Text, Paragraph, FormLabel, InputText } from 'prixa-design-kit/dist';
import RadioButton from '../radio/Radio';
import { PreconReply } from '../../wrappers/diagnostic/ConversationPage';

export interface PrecondProps {
  setConvoState: (params: { state: string }) => void;
  callFunction: (reply?: object) => void;
  title: Array<string>;
  loading?: boolean;
  list: Array<PreconReply>;
  preconditionData: any;
  setPreconditionData: any;
}

export const BasicPrecondition: React.FC<PrecondProps> = (props: PrecondProps) => {
  const [showMonth, setShow] = useState(false);
  const [ageMonth, setAgeMonth] = useState(0);
  const [ageYear, setAgeYear] = useState(0);
  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);
  const [gender, setGender] = useState({});

  const [errAgeMonth, setErrAgeMonth] = useState(false);
  const [errAgeYear, setErrAgeYear] = useState(false);
  const [errHeight, setErrHeight] = useState(false);
  const [errWeight, setErrWeight] = useState(false);
  const [errGender, setErrGender] = useState(true);

  useEffect(() => {
    if (Number(ageYear) > 0) {
      setShow(false);
    } else {
      setShow(true);
    }

    if (Number(ageMonth) > 0 && Number(ageMonth) < 13 && Number(ageYear) === 0) {
      setErrAgeMonth(false);
      setErrAgeYear(false);
    } else if (Number(ageYear) < 0 || Number(ageYear) > 100) {
      setErrAgeYear(true);
    } else if (Number(ageYear) > 0 && Number(ageYear) < 100) {
      setErrAgeMonth(false);
      setErrAgeYear(false);
    } else {
      setErrAgeMonth(true);
    }
  }, [ageMonth, ageYear]);

  useEffect(() => {
    if (height > 0 && height < 300) {
      setErrHeight(false);
    } else {
      setErrHeight(true);
    }
  }, [height]);

  useEffect(() => {
    if (weight > 0 && weight < 500) {
      setErrWeight(false);
    } else {
      setErrWeight(true);
    }
  }, [weight]);

  useEffect(() => {
    if (JSON.stringify(gender) === '{}') {
      setErrGender(true);
    } else {
      setErrGender(false);
    }
  }, [gender]);

  const genderList = [];

  for (let index = 0; index < props.list.length; index++) {
    if (props.list[index].type === 'gender') {
      genderList.push(props.list[index]);
    }
  }

  let temptPeconditionData: any;

  const firstSubmit = (event: any) => {
    event.preventDefault();
    temptPeconditionData = [
      {
        height: Number(height),
        weight: Number(weight),
        type: 'bmi',
      },
      {
        ageYear: Number(ageYear),
        ageMonth: Number(ageMonth),
        type: 'age',
      },
    ];
    temptPeconditionData.push(gender);
    props.setPreconditionData(temptPeconditionData);
    props.setConvoState({ state: 'askPrecondition1' });
  };

  return (
    <div className="prixa-container">
      <form
        onSubmit={event => {
          firstSubmit(event);
        }}
      >
        <div className="prixa-title">
          <Paragraph scale="question">Mohon lengkapi informasi berikut:</Paragraph>
          <Paragraph scale="caption" className="prixa-title-long-sub">
            Informasi ini akan membantu Prixa <br />
            menentukan pertanyaan yang relevan bagi Anda.
          </Paragraph>
          <div className="row margin-baseB">
            <FormLabel small>JENIS KELAMIN</FormLabel>
            <RadioButton data={genderList} setData={setGender} selected={gender}></RadioButton>
          </div>
          <div className="row margin-tinyB">
            <FormLabel small>UMUR</FormLabel>
            <div className="flex-inline">
              <div className="flex-inline margin-tinyR">
                <InputText
                  type="number"
                  placeholder="99"
                  max={100}
                  small
                  maxLength={3}
                  setData={setAgeYear}
                  errors={errAgeYear}
                />
                <Text scale="content" className="margin-tinyX">
                  tahun
                </Text>
              </div>
              {!showMonth || (
                <div className="flex-inline margin-tinyR">
                  <InputText
                    type="number"
                    placeholder="12"
                    maxLength={2}
                    setData={setAgeMonth}
                    max={12}
                    small
                    errors={errAgeMonth}
                  />
                  <Text scale="content" className="margin-tinyX">
                    bulan
                  </Text>
                </div>
              )}
            </div>
          </div>
          <div className="row flex-inline">
            <div className="margin-baseR">
              <FormLabel small>TINGGI (CM)</FormLabel>
              <InputText
                className=""
                type="number"
                max={300}
                setData={setHeight}
                placeholder="170"
                small
                errors={errHeight}
              />
            </div>
            <div className="margin-baseR">
              <FormLabel small>BERAT (KG)</FormLabel>
              <InputText
                className=""
                type="number"
                max={500}
                setData={setWeight}
                placeholder="65"
                small
                errors={errWeight}
              />
            </div>
          </div>
        </div>
        <div className="prixa-right-button">
          <Button
            type="submit"
            size="option"
            variant="primary"
            disabled={errAgeMonth || errAgeYear || errHeight || errWeight || errGender}
          >
            Lanjut
          </Button>
        </div>
      </form>
    </div>
  );
};
