import React from 'react';
import { Text, Paragraph, List } from 'prixa-design-kit/dist';

const SummarySideSheet = (props: any) => {
  const { user, profiles, symptoms, userDetails } = props;

  const findInProfiles = (name: any) => {
    const profile = profiles.find((element: any) => element.type === name);
    if (profile) {
      return profile.nameIndo;
    } else {
      return '';
    }
  };

  const bmi = findInProfiles('bmi');

  const height = user.height + ' cm';
  const weight = user.weight + ' kg';

  const age = userDetails.ageYear;

  const isCanDrinkAndSmoke = !(age < 17);

  const healthStatus = ['Diabetes', 'Minum Alkohol', 'Hipertensi', 'Perokok'];

  const defaultPrecondition = profiles
    .filter((element: any) => {
      const notAgeOrGenderOrBmi = !(element.type === 'age' || element.type === 'gender' || element.type === 'bmi');
      return notAgeOrGenderOrBmi;
    })
    .map((element: any) => element.nameIndo);

  healthStatus
    .filter(status => {
      if (!defaultPrecondition.find((precondition: any) => precondition === status)) {
        return status;
      }
      return '';
    })
    .forEach(data => {
      if (data === 'Diabetes' || data === 'Hipertensi') {
        defaultPrecondition.push('Tidak Mengidap ' + data);
      } else if (data === 'Minum Alkohol' && isCanDrinkAndSmoke) {
        defaultPrecondition.push('Tidak ' + data);
      } else if (data === 'Perokok' && isCanDrinkAndSmoke) {
        defaultPrecondition.push('Tidak Merokok');
      }
    });

  const preconditions = defaultPrecondition;
  const mainComplaintFiltered = symptoms.filter((element: any) => {
    const isMainComplaint = element.chief === true && element.answer === 'yes';
    return isMainComplaint;
  });
  const mainComplaints = mainComplaintFiltered.map((element: any) => {
    if (element.propNames && element.propNames.length) {
      return [element.symptomName, ...element.propNames].join(', ');
    }
    return element.symptomName;
  });
  const otherComplaintFiltered = symptoms.filter((element: any) => {
    const isOtherComplaint = element.chief === undefined && element.answer === 'yes';
    return isOtherComplaint;
  });
  const otherComplaints = otherComplaintFiltered.map((element: any) => {
    if (element.propNames && element.propNames.length) {
      return [element.symptomName, ...element.propNames].join(', ');
    }
    return element.symptomName;
  });
  const notShowingUpComplaintFiltered = symptoms.filter((element: any) => {
    const isNotShowingUp = element.chief === undefined && element.answer === 'no';
    return isNotShowingUp;
  });
  const notShowingUpComplaints = notShowingUpComplaintFiltered.map((element: any) => {
    return element.symptomName;
  });
  const unknownComplaintFiltered = symptoms.filter((element: any) => {
    const isUnknown = element.chief === undefined && element.answer === 'unknown';
    return isUnknown;
  });
  const unknownComplaints = unknownComplaintFiltered.map((element: any) => {
    return element.symptomName;
  });

  return (
    <div className="prixa-container is-top">
      <div className="margin-baseB">
        <Text scale="pagesubtitle">STATUS KESEHATAN & KEBIASAAN</Text>
        <List scale="content" type="ul">
          {preconditions.map((precondition: any, i: number) => {
            return (
              <li key={i}>
                <Text scale="content">{precondition}</Text>
              </li>
            );
          })}
        </List>
      </div>
      <div className="margin-baseB">
        <Text scale="pagesubtitle">BMI</Text>
        <Paragraph scale="content">
          {height}, {weight} ({bmi ? bmi : 'Tidak Obesitas'})
        </Paragraph>
      </div>
      <div className="margin-baseB">
        <Text scale="pagesubtitle">KELUHAN UTAMA</Text>
        <SummaryList complaints={mainComplaints} />
      </div>
      <div className="margin-baseB">
        <Text scale="pagesubtitle">KELUHAN LAIN</Text>
        <SummaryList complaints={otherComplaints} />
      </div>
      <div className="margin-baseB">
        <Text scale="pagesubtitle">TIDAK ADA</Text>
        <SummaryList complaints={notShowingUpComplaints} />
      </div>
      <div className="margin-baseB">
        <Text scale="pagesubtitle">TIDAK TAHU</Text>
        <SummaryList complaints={unknownComplaints} />
      </div>
    </div>
  );
};

const SummaryList = (props: any) => {
  const { complaints } = props;
  if (complaints.length) {
    return complaints.map((complaint: any, i: number) => {
      return (
        <li key={i}>
          <Text scale="content">{complaint}</Text>
        </li>
      );
    });
  } else {
    return <Paragraph scale="content">-</Paragraph>;
  }
};

export default SummarySideSheet;
