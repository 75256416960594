import React, { useEffect } from 'react';
import { Text } from 'prixa-design-kit/dist';
import { SetContainerProps } from '../../App';
/* eslint-disable */
const dummyData = [
  {
    date: new Date(),
    complaint: 'Ketidakmampuan untuk berjinjit atau berdiri dengan baik dan benar',
    name: 'Devi',
    age: 26,
    gender: 'Perempuan'
  },
  {
    date: new Date(),
    complaint: 'Mata buram',
    name: 'Devi',
    age: 26,
    gender: 'Perempuan'
  },
  {
    date: new Date(),
    complaint: 'Dada berdebar',
    name: 'Devi',
    age: 26,
    gender: 'Perempuan'
  },
  {
    date: new Date(),
    complaint: 'Pendengaran mulai salah',
    name: 'Devi',
    age: 26,
    gender: 'Perempuan'
  }
];
/* eslint-enable */

const DiagnosisHistoryPage: React.FC<SetContainerProps> = (props: SetContainerProps) => {
  useEffect(() => {
    props.setHeader('Riwayat Keluhan');
  }, [props]);

  return (
    <div className="prixa-container is-top">
      <div className="prixa-timeline">
        <div className="list">
          <div className="prixa-flex-column">
            <Text scale="pageTitle" style={{ color: '#0146ab' }}>
              Mata merah
            </Text>
            <Text scale="content">Myrtyl, 26, Perempuan</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DiagnosisHistoryPage };
