import React from 'react';
import './header.scss';
import { Text, Icon } from 'prixa-design-kit/dist';
import SideSheet, { openSideSheet } from '../sidesheet/SideSheet';
import MenuPage from '../menu/Menu';

const imgHeader = `${process.env.REACT_APP_ASSET_URL}/prixa-header.png`;

interface HeaderProps {
  title?: string;
  subtitle?: string;
}

const Header = (props: HeaderProps) => {
  const [modal, setModal] = React.useState(false);
  const [{ title, subtitle }, setHeader] = React.useState({ title: '', subtitle: '' });

  /* eslint-disable  */
  React.useEffect(() => {
    setHeader({ title: props.title || '', subtitle: props.subtitle || '' });
  }, [props.title]);
  /* eslint-enable  */

  return (
    <div className="prixa-header">
      <div onClick={() => window.history.back()} className="prixa-menuback">
        <Icon type="faChevronLeft" style={{ width: 16 }} />
      </div>
      <div className="prixa-caption">
        <Text scale="headerTitle">
          {(() => {
            if (title) {
              return title;
            } else {
              return (
                <img
                  loading="lazy"
                  width="50px"
                  alt="logo"
                  className="prixa-header-image"
                  src={imgHeader}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (String(localStorage.getItem('loginToken')).length > 10) {
                      window.location.href = '/home';
                    } else {
                      window.location.href = '/';
                    }
                  }}
                />
              );
            }
          })()}
        </Text>
        {(() => {
          if (subtitle) {
            return <Text scale="headerSubtitle">{subtitle}</Text>;
          }
        })()}
      </div>
      <div className="prixa-menubar">
        <span
          onClick={() => {
            setModal(true);
            openSideSheet();
          }}
        >
          <Icon color="primary" type="faBars" width="16px" />
        </span>
      </div>
      <SideSheet setModal={setModal} show={modal} title="Menu" content={<MenuPage></MenuPage>}></SideSheet>
    </div>
  );
};

export default Header;
