import React from 'react';
import '../menu/menu.scss';
import { Text, Icon } from 'prixa-design-kit/dist';

const logoMark = `${process.env.REACT_APP_ASSET_URL}/prixa-logomark.png`;
const imgArtikel = `${process.env.REACT_APP_ASSET_URL}/icon/artikel.png`;
const imgPers = `${process.env.REACT_APP_ASSET_URL}/icon/pers.png`;
const imgHubungi = `${process.env.REACT_APP_ASSET_URL}/icon/hubungi.png`;

interface Menu {
  title: string;
  menuItem: MenuItem[];
}

interface MenuItem {
  title: string;
  link: string;
  icon: string;
  target: string;
  enable?: boolean;
}

const MenuPage = () => {
  // const history = useHistory();
  // console.log(history);

  const menu: Menu[] = [
    {
      title: 'Prixa Sekarang',
      menuItem: [
        { title: 'Sistem Periksa Berbasis AI', link: '/', icon: logoMark, target: '_self', enable: true },
        // { title: 'Kamus Kondisi', link: '#', icon: logoMark, target: '_self' },
        // { title: 'Janji Temu Dokter', link: '#', icon: logoMark, target: '_self' },
        // { title: 'Pesan Antar Farmasi', link: '#', icon: logoMark, target: '_self' },
        // { title: 'Tes Lab', link: '#', icon: logoMark, target: '_self' },
        {
          title: 'Konsultasi via Chat',
          link: '/initiate',
          icon: logoMark,
          target: '_self',
          enable: process.env.REACT_APP_TELEMED_ON === 'true' ? true : false,
        },
      ],
    },
    {
      title: 'Website',
      menuItem: [
        { title: 'Tentang Prixa', link: 'https://prixa.ai', icon: logoMark, target: '_blank', enable: true },
        { title: 'Blog', link: 'https://prixa.ai/artikel', icon: imgArtikel, target: '_blank', enable: true },
        { title: 'Liputan Pers', link: 'https://prixa.ai/pers', icon: imgPers, target: '_blank', enable: true },
        { title: 'Hubungi Kami', link: 'mailto:halo@prixa.ai', icon: imgHubungi, target: '_blank', enable: true },
      ],
    },
  ];

  return (
    <div className="prixa-container is-top is-full">
      {(process.env.REACT_APP_USER_ON === 'true' ? (
        true
      ) : (
        false
      )) ? (
        <div className="margin-largeB">
          <MenuButton />
        </div>
      ) : (
        <div className="margin-baseB" />
      )}
      {menu.map((menu: Menu, key: number) => {
        return (
          <React.Fragment key={key}>
            <div className="prixa-menu-title">
              <Text scale="pageTitle">{menu.title}</Text>
            </div>
            <div className="prixa-menu margin-largeB">
              {menu.menuItem.map((menu: MenuItem, key: number) => {
                return <Menu key={key} menu={menu} />;
              })}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const Menu = ({ menu }: any) => {
  if (menu.enable) {
    return (
      <a href={menu.link} rel="noopener noreferrer" target={menu.target}>
        <div className="prixa-menu-list">
          <div className="prixa-menu-icon">
            <img loading="lazy" alt={menu.title} src={menu.icon} width="16"></img>
          </div>
          <div className="prixa-menu-link">
            <Text scale="content">{menu.title}</Text>
          </div>
        </div>
      </a>
    );
  } else {
    return <span />;
  }
};

const MenuButton = () => {
  const token = localStorage.getItem('loginToken');

  if (token) {
    return <ProfileButton />;
  } else {
    return <LoginSignUpButton />;
  }
};

const LoginSignUpButton = () => {
  return (
    <span className="prixa-continue-button">
      <a href="/continue" className="button">
        <Icon type="faUser" style={{ color: 'white', fontSize: '16px', marginRight: '4px' }} />
        <span> Daftar</span>
      </a>
      <span className="button-separator" />
      <a href="/continue" className="button">
        <Icon type="faSignInAlt" style={{ color: 'white', fontSize: '16px', marginRight: '4px' }} />
        <span> Masuk</span>
      </a>
    </span>
  );
};

const ProfileButton = () => {
  const [url, setURL] = React.useState('');
  const [loadUrl, setLoadUrl] = React.useState(true);

  const profileDataLocalStorage = localStorage.getItem('profileData');
  const profileData = JSON.parse(String(profileDataLocalStorage));

  React.useEffect(() => {
    // setURL(profileData.avatarURL || 'https://picsum.photos/200');
    setURL('https://picsum.photos/200');
    setTimeout(() => {
      setLoadUrl(false);
    }, 1000);
  }, [profileData.avatarURL]);

  return (
    <a href="/profile" className="prixa-profile-button">
      <span className="profile">
        {loadUrl ? (
          <Icon style={{ height: '14px', color: '#0146AB', marginRight: '16px' }} type="faCircleNotch" spin />
        ) : (
          <img
            loading="lazy"
            alt="User Profile"
            src={url}
            style={{ borderRadius: '100%', height: '24px', width: '24px', objectFit: 'cover', marginRight: '16px' }}
          />
        )}
        <Text scale="content" style={{ fontWeight: 'bold' }}>
          {profileData.name}
        </Text>
      </span>
      <span>
        <Icon style={{ color: '#4C4F54', marginRight: '16px' }} type="faChevronRight" />
      </span>
    </a>
  );
};

export default MenuPage;
