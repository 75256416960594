import React from 'react';
import Carousel from '../../components/carousel/Carousel';
import { Button } from 'prixa-design-kit/dist';
import { Link, Redirect } from 'react-router-dom';

const IntroPage: React.FC = () => {
  const storageSessionId = localStorage.getItem('sesId');

  if (storageSessionId) {
    return <Redirect to="/conversation" />;
  }

  return (
    <div className="prixa-container">
      <Carousel></Carousel>
      <div className="prixa-right-button">
        <Link to="/consent">
          <Button size="xLarge" variant="primary">
            Prixa Sekarang
          </Button>
        </Link>
      </div>
    </div>
  );
};

export { IntroPage };
